import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import Modal from "antd/lib/modal";

import { DateHelper, DATE_FORMAT_7 } from "@utils/DateHelper";
import { StorageKeys } from "@utils/LocalStorage";
import { getMobileOperatingSystem } from "@utils/DeviceInfoUtils";

import { resetNetworkLayerCache } from "@common/network/clientCacheHandler";
import { IMaintenanceErrorPayload } from "@common/redux/auth/interface";
import { maintenanceModeSelector } from "@common/redux/auth/selectors";

import { translate } from '@translations/Translater';

import { globalMessages } from '@components/messages';

import navigator from '@navigator/index';
import { NavigationUrl, URLs } from '@navigator/NavigationUrl';

import { Platform, WebViewMessageType } from '@constants/config';

import { rootMessages } from "@containers/Root/messages";

import "./styles.less";

interface IState {
    hasRenderError: boolean;
    hasChunkError: boolean;
}

interface IProps {
    t: any;
    maintenanceMode: IMaintenanceErrorPayload;
    children: React.ReactNode;
}

const chunkError = 'ChunkLoadError';

class ErrorBoundary extends React.Component<IProps, IState> {

    state = {
        hasChunkError: false,
        hasRenderError: false
    };

    static getDerivedStateFromError(error: any) {
        if (error.toString().includes(chunkError)) {
            return {
                hasChunkError: true
            };
        } else {
            return {
                hasRenderError: true
            };
        }
    }

    private reloadPage = () => {
        location.reload();
        this.setState({ hasChunkError: false });
        return false;
    }

    private gotoHomePage = () => {
        this.setState({ hasRenderError: false });

        const isWebView = window.localStorage.getItem(StorageKeys.IS_WEBVIEW);

        if (isWebView) {
            // @ts-ignore
            window.ReactNativeWebView.postMessage(JSON.stringify(
                {
                    MESSAGE_TYPE: WebViewMessageType.ERROR_GO_TO_HOME_PAGE,
                }
            ));
        } else {
            navigator.replace(NavigationUrl.generate(URLs.home));
            location.reload();
        }

        return false;
    }

    private chunkErrorPopup = () => {
        const { t } = this.props;
        return Modal.info({
            title: translate(t, globalMessages.chunkError),
            style: null,
            okText: translate(t, globalMessages.update),
            content: (
                <div>
                    <p>{translate(t, globalMessages.chunkErrorReloadMessage)}</p>
                </div>
            ),
            onOk: () => this.reloadPage(),
        })
    }

    private appErrorPopup = () => {
        const { t } = this.props;
        return Modal.info({
            title: translate(t, globalMessages.appError),
            style: null,
            okText: translate(t, globalMessages.goToHomepage),
            onOk: () => this.gotoHomePage(),
        })
    }

    private maintenanceModeErrorPopUp = () => {
        const { t, maintenanceMode } = this.props;
        return Modal.info({
            // @ts-ignore
            title: `${translate(t, rootMessages.underMaintenence, { downTime: DateHelper.getDateTime(maintenanceMode?.downTime, DATE_FORMAT_7) })}`,
            okText: translate(t, rootMessages.tryAgain),
            onOk: () => window.location.reload(),
        })
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (!prevState.hasChunkError && this.state.hasChunkError) {
            this.reloadPage();
        }
    }

    private showPopup() {
        const chromeVersion = parseInt(window.navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./)[2]);
        return (
            <div className="chrome_update_notification">
                <h2>{translate(this.props.t, rootMessages.updateChrome)}</h2>
                {/* @ts-ignore */}
                <p>{translate(this.props.t, rootMessages.updateChromeMessage, { chromeVersion: `${chromeVersion}.x.x` })}</p>
            </div>
        )
    }

    private showWebViewPopUp() {
        return (
            <div className="chrome_update_notification">
                <h2>{translate(this.props.t, rootMessages.updateWebView)}</h2>
                <p>{translate(this.props.t, rootMessages.updateWebViewMessage)}</p>
            </div>
        )
    }

    private getChromeVersion() {
        const userAgent = window.navigator.userAgent;
        return parseInt(userAgent.match(/Chrom(e|ium)\/([0-9]+)\./)[2]);
    }

    private isChrome() {
        const userAgent = window.navigator.userAgent;
        return /Chrome/.test(userAgent) && /Google Inc/.test(window.navigator.vendor);
    }

    render() {
        const isChrome = this.isChrome();
        const isWebView = !!(window.localStorage.getItem(StorageKeys.IS_WEBVIEW));
        let chromeVersion = null;
        if (isChrome) {
            chromeVersion = this.getChromeVersion();
        }

        if (!isWebView && isChrome && chromeVersion && chromeVersion < 93) {
            return this.showPopup();
        } else if (isWebView && (getMobileOperatingSystem() === Platform.ANDROID) && chromeVersion && chromeVersion < 93) {
            return this.showWebViewPopUp();
        } else if (this.props?.maintenanceMode?.flag) {
            return this.maintenanceModeErrorPopUp();
        } else if (this.state.hasRenderError) {
            return this.appErrorPopup();
        } else if (this.state.hasChunkError) {
            resetNetworkLayerCache();
            this.chunkErrorPopup();
        }
        return this.props.children;
    }
}

export const mapStateToProps = (state) => {
    return {
        maintenanceMode: maintenanceModeSelector(state)
    };
};

export default withTranslation()(connect(mapStateToProps)(ErrorBoundary));
