import { CourseCompetencyModel } from "@common/domain/models/CourseCompetency";

import { CourseModel, ILMSSession } from "@models/Course";
import {CourseToc} from "@models/CourseToc";

import { IState } from "@store/webInterfaces";
import { ICourseTag, IFacultyCourses } from "./actions";

const isCourseStateUndefined = (state: IState): boolean =>
  state.course === undefined;

const isCourseErrorsUndefined = (state: IState): boolean =>
  state.course.errors === undefined;

const isCourseLoaderUndefined = (state: IState): boolean =>
  state.course.loaders === undefined;

export const getCourseMapSelector = (state: IState): any => {
  if (isCourseStateUndefined(state) || !state.course.byId) return {};

  return state.course.byId;
};

export const getCourseByIdSelector = (state: IState, courseId: string): CourseModel => {
  if (isCourseStateUndefined(state) || !state.course.byId) return null;

  return state.course.byId[courseId];
};


export const fetchMultipleCoursesData = (state: IState): CourseModel[] => {
  if (isCourseStateUndefined(state) || !state.course.courses) return [];
  return state.course.courses.map((courseId) => getCourseByIdSelector(state, courseId));
};

export const fetchCourseListSelector = (state: IState): CourseModel[] => {
  if (isCourseStateUndefined(state) || !state.course.courseList) return [];
  return state.course.courseList;
};

export const fetchMultipleCourseDetailsLoadingSelector = (state: IState): boolean => {
  if (
    isCourseStateUndefined(state) ||
    isCourseLoaderUndefined(state) ||
    !state.course.loaders.fetchCourseDetails
  )
    return false;
  return state.course.loaders.fetchCourseDetails;
};

export const fetchMultipleCourseDetailsErrorSelector = (state: IState): string => {
  if (
    isCourseStateUndefined(state) ||
    isCourseErrorsUndefined(state) ||
    !state.course.errors.fetchCourseDetails
  )
    return "";
  return state.course.errors.fetchCourseDetails;
};

export const fetchCourseCompetenciesLoaderSelector = (state: IState): boolean => {
  if (isCourseStateUndefined(state) ||
    isCourseLoaderUndefined(state) ||
    !state.course.loaders.fetchCourseCompetencies)
    return false;

  return state.course.loaders.fetchCourseCompetencies;
};

export const fetchCourseCompetenciesErrorSelector = (state: IState): string => {
  if (
    isCourseStateUndefined(state) ||
    isCourseErrorsUndefined(state) ||
    !state.course.errors.fetchCourseCompetencies
  )
    return "";

  return state.course.errors.fetchCourseCompetencies;
}

export const fetchCourseCompetenciesSelector = (state: IState): CourseCompetencyModel[] => {
  if (
    isCourseStateUndefined(state) ||
    !state.course.courseCompetencies
  )
    return null;

  return state.course.courseCompetencies;
}

export const fetchCourseDetailsLoadingSelector = (state: IState): boolean => {
  if(isCourseStateUndefined(state) || isCourseStateUndefined(state) || !state.course.loaders.fetchCourseDetails) return false
  return state.course.loaders.fetchCourseDetails
}

// course tags
export const fetchCourseTagsLoaderSelector = (state: IState): boolean => state?.course?.loaders?.fetchCourseTags || false;
export const fetchCourseTagsErrorSelector = (state: IState): string => state?.course?.errors?.fetchCourseTags || "";
export const fetchCourseTagsSelector = (state: IState): ICourseTag[] => state?.course?.courseTags || null;

// faculty courses
export const fetchFacultyCoursesLoaderSelector = (state: IState): boolean => state?.course?.loaders?.facultyCourses || false;
export const fetchFacultyCoursesErrorSelector = (state: IState): string => state?.course?.errors?.facultyCourses || "";
export const fetchFacultyCoursesSelector = (state: IState): IFacultyCourses[] => state?.course?.facultyCourses || null;

export const fetchEnrolledCoursesLoaderSelector = (state: IState, status: string): boolean => state?.course?.loaders?.enrolledCourses[status] || false;
export const fetchEnrolledCoursesErrorSelector = (state: IState, status: string): string => state?.course?.errors?.enrolledCourses[status] || "";
export const fetchEnrolledCoursesSelector = (state: IState, status: string) => state?.course?.enrolledCourses[status] || [];
export const fetchEnrolledCoursesCountSelector = (state: IState, status: string) => state?.course?.enrolledCoursesCount[status] || 0;

export const fetchCourseActivityPercentageMapByIdSelector = (state: IState, courseId: string) => state?.course?.courseActivityPercentageMap[courseId] || {};
export const fetchCourseActivityPercentageLoaderSelector = (state: IState) => state?.course?.loaders?.courseActivityPercentage || false;

//Course content TOC
export const fetchCourseTocLoaderSelector = (state: IState, courseId: string): boolean => state?.course?.loaders?.courseContentToc[courseId] || false;
export const fetchCourseTocErrorSelector = (state: IState, courseId: string): string => state?.course?.errors?.courseContentToc[courseId] || "";
export const fetchCourseContentTocSelector = (state: IState, courseId: string): CourseToc => state?.course?.courseContentTocMap[courseId] || null;
export const fetchUpdatingCourseTocLoaderSelector = (state: IState) => state?.course?.loaders?.updatingCourseToc || false;

export const fetchLmsSessionLoaderSelector = (state: IState): boolean => state?.course?.loaders?.lmsSession || false;
export const fetchLmsSessionErrorSelector = (state: IState): string => state?.course?.errors?.lmsSession || "";
export const fetchLmsSessionSelector = (state: IState): ILMSSession => state?.course?.lmsSession || null;

export const getCertificateIdDataSelector = (state:IState)=>state?.course?.getCertificateIdData|| null; 
export const getCertificateIdDataLoaderSelector = (state:IState)=> state?.course?.loaders?.getCertificateIdData || false;
export const getCertificateIdDataErrorSelector = (state:IState)=> state?.course?.errors?.getCertificateIdData || "";

export const getDiscourseConnectSelector = (state:IState)=>state?.course?.connectDiscourse|| null; 
export const getDiscourseConnectLoaderSelector = (state:IState)=> state?.course?.loaders?.connectDiscourse || false;
export const getDiscourseConnectErrorSelector = (state:IState)=> state?.course?.errors?.connectDiscourse || "";
export const fetchActivityStatusLoaderSelector = (state: IState): boolean => state?.course?.loaders?.activityStatus || false;

export const fetchActivityStatusErrorSelector = (state: IState): string => state?.course?.errors?.activityStatus || '';

export const fetchAiLessonMappingDataSelector = (state: IState): any => state?.course?.aiLessonMappingData || {};
export const fetchAiLessonMappingLoaderSelector = (state: IState): string => state?.course?.errors?.aiLessonMappingData || "";
export const fetchAiLessonMappingErrorSelector = (state: IState): boolean => state?.course?.loaders?.aiLessonMappingData || false;
export const isChangedInActivityStatusSelector = (state: IState): boolean => state?.course?.isChangedInActivityStatus || false;

export const fetchCertifiedFacultyCoursesSelector = (state: IState): CourseModel[] => state?.course?.fetchCertifiedFacultyCourses || [];
export const fetchCertifiedFacultyCoursesLoaderSelector = (state: IState): boolean => state?.course?.loaders?.fetchCertifiedFacultyCourses || false;
export const fetchCertifiedFacultyCoursesErrorSelector = (state: IState): string => state?.course?.errors?.fetchCertifiedFacultyCourses || "";
export const fetchCertifiedFacultyCoursesCountSelector = (state: IState): number => state?.course?.facultyCertifiedCoursesCount || 0;

export const fetchLatestCoursesByTagMapSelector = (state: IState, group: string) => state?.course?.courseByTagMap[group] || {};
export const fetchLatestCoursesByTagMapLoaderSelector = (state: IState) => state?.course?.loaders?.fetchLatestCoursesByTags || false;

export const getOpenCourseListSelector = (state: IState) => {return state?.course?.openCourseList || [];};
export const getOpencourseTotalCountSelector = (state: IState) => {return state?.course?.openCourseListCount || 0;}
export const getOpenCourseListLoaderSelector = (state: IState) => {return state?.course?.loaders?.openCourseList || false;};
export const getOpenCourseListErrorSelector = (state: IState) => {return state?.course?.errors?.openCourseList || ""};

export const enrollToCourseSelector = (state: IState) => {return state?.course?.enrollToCourse || {};};
export const enrollToCourseLoaderSelector = (state: IState) => {return state?.course?.loaders?.enrollToCourse || false;};
export const enrollToCourseErrorSelector = (state: IState) => {return state?.course?.errors?.enrollToCourse || ""};

export const enrolledCoursesListSelector = (state: IState) => state?.course?.enrolledCourseList || []
export const getEnrolledCourseListLoader = (state:IState) => state?.course?.loaders?.enrolledCourseList||false;

export const fetchEnrolledCourseKeysLoaderSelector = (state: IState, status: string): boolean => state?.course?.loaders?.enrolledCourseKeys[status] || false;
export const fetchEnrolledCourseKeysErrorSelector = (state: IState): string => state?.course?.errors?.enrolledCourseKeys || "";
export const fetchEnrolledCourseKeysSelector = (state: IState, status: string) => state?.course?.enrolledCourseKeysMap[status] || [];

export const getMicroCertificateIdSelector = (state:IState, instanceId:string) => state?.course?.microCertificateIdMap[instanceId];
export const getMicroCertificateIdLoaderSelector = (state:IState, instanceId:string) => state?.course?.loaders?.microCertificateIdMap[instanceId];
export const getMicroCertificateIdErrorSelector = (state:IState, instanceId:string) => state?.course?.errors?.microCertificateIdMap[instanceId];

export const getForumDetailsByCourseIdSelector = (state:IState, courseId:string) => state?.course?.forumDetailsByCourseIdMap[courseId];
export const getForumDetailsByCourseIddLoaderSelector = (state:IState, courseId:string) => state?.course?.loaders?.forumDetailsByCourseIdMap[courseId];
export const getForumDetailsByCourseIdErrorSelector = (state:IState, courseId:string) => state?.course?.errors?.forumDetailsByCourseIdMap[courseId];

export const discourseSyncToGroupDataSelector = (state: IState) => {return state?.course?.discourseSyncToGroup || {}};
export const discourseSyncToGroupLoaderSelector = (state: IState) => {return state?.course?.loaders?.discourseSyncToGroup || false;};
export const discourseSyncToGroupErrorSelector = (state: IState) => {return state?.course?.errors?.discourseSyncToGroup || ""};

export const guestCoursesListSelector = (state:IState) => state?.course?.guestCourseList || []; 
export const guestCoursesListLoaderSelector = (state:IState) => state?.course?.loaders?.guestCourseList || false;
export const guestCoursesListErrorSelector = (state:IState) => state?.course?.errors?.guestCourseList || "";

export const guestCoursesDetailSelector = (state:IState) => state?.course?.guestCourseDetail || null; 
export const guestCoursesDetailLoaderSelector = (state:IState) => state?.course?.loaders?.guestCourseDetail || false;
export const guestCoursesDetailErrorSelector = (state:IState) => state?.course?.errors?.guestCourseDetail || "";
export const getActiveModuleIndex = (state:IState) => state?.course?.activeModuleId||null;
