import {
  deserializeJsonObj,
  deserializeJsonObjCollection,
} from "@utils/ObjectMapperUtil";

import { APIBaseServices, clearCacheForService } from "@common/network/clientCacheHandler";
import { APIv1, APIv2 } from "@common/network/constants";
import {
  ICreateBatchPayload,
  IFetchUserBatchPayload,
  IJoinBatchMessage,
} from "@common/redux/batch/interface";

import { userRoles } from "@redux/constants";

import { BatchModel } from "@models/Batch";
import { BatchCompetenciesModal } from "@models/BatchCompetenciesModal";

import { BatchStatus } from "@constants/config";

import { ApiClient } from "@network/client";
import { BatchDeliveryModel } from "../models/BatchDeliveryModel";
import { ProfileModel } from "../models/Profile";
import { RMBatchModel } from "../models/RMBatchModel";

const ENDPOINTS = {
  fetchBatchesUserOwned: (userId: string) => `${APIv1}/organizationservice/users/${userId}/batches`,
  fetchBatchStudentsCount: () => `${APIv1}/organizationservice/batches/students/count`,
  closeBatch: (batchId: string) =>
    `${APIv1}/organizationservice/batches/${batchId}/close`,
  fetchCompetenciesList: (batchId: number) =>
    `${APIv1}/organizationservice/batches/competency/${batchId}`,
  updateCompetencyDate: (batchId: number, competencyId: string) =>
    `${APIv1}/organizationservice/batches/${batchId}/competency/${competencyId}`,
  fetchBatchDeliveryModels: () =>
    `${APIv1}/organizationservice/batchDeliveryModels`,
  createBatch: () => `${APIv1}/organizationservice/batches`,
  fetchBatchesDetails: () => `${APIv2}/organizationservice/batches`,
  joinBatch: () => `${APIv1}/organizationservice/batches/join`,
  fetchUserBatch: (userId: string) =>
    `${APIv1}/organizationservice/users/${userId}/batches`,
  fetchStudentAndFacultyCombinedBatch: (userId: string) => `${APIv1}/organizationservice/users/${userId}/batches`,
  fetchBatchMembers: (type: string) => `${APIv1}/organizationservice/batches/${type}`,
  fetchAllBatches: () => `${APIv2}/organizationservice/batches`,
  fetchRMAssignedBatches: () => `${APIv1}/organizationservice/batches/regionalManager`,
  updateBatch: (batchId: string) => `${APIv1}/organizationservice/batches/${batchId}`,
  addOrRemoveFaculty: () => `${APIv1}/organizationservice/batches/batchFaculties`,
  fetchUserBatchPermissionsByBatchId: (batchId: string, userId: string) => `${APIv1}/organizationservice/batches/${batchId}/users/${userId}/functionalityFlags`,
  uploadStudents: () => `${APIv1}/iamservice/bulkupload/inviteUserToBatch`,
  addStudent: (batchKey: string) => `${APIv1}/iamservice/invite/students/batch/${batchKey}`,
  removeStudent: (batchId: number, studentId: string) => `${APIv1}/organizationservice/batches/${batchId}/students/${studentId}`,
  invitedStudent: (batchKey: string) => `${APIv1}/organizationservice/userInvitation?batchKey=${batchKey}`,
  resendOTP: () => `${APIv1}/iamservice/resendBatchInvitationMails`,
  inviteSingleStudent: (batchKey: string) => `${APIv1}/iamservice/invite/students/batch/${batchKey}`,
  fetchBulkUploadStatusById:(bulkUploadId:string) => `${APIv1}/iamservice/bulkUploadHistory/${bulkUploadId}`,
};

class BatchRepository {
  private apiClient: any;

  constructor(apiClient: any) {
    this.apiClient = apiClient;
  }

  public fetchBatchesUserOwned = async (userId: string, status?: string, page?: number, limit?: number) => {
    const params = {
      status,
      page,
      limit,
    }
    const response: any = await this.apiClient.get(ENDPOINTS.fetchBatchesUserOwned(userId), params, true);

    const batches = deserializeJsonObjCollection(response.batches, BatchModel, 'fetchBatchesUserOwned') as BatchModel[];
    return {
      batches,
      total: response.total,
    };
  };

  public fetchBatchStudentsCount = async (batchIds: number[]): Promise<any> => {
    const response = await this.apiClient.get(ENDPOINTS.fetchBatchStudentsCount(), {
      batchIds
    }, true);
    return response;
  }

  public closeBatch = async (batchId: string) => {
    clearCacheForService([APIBaseServices.ORGANISATION_SERVICE]);
    const response = await this.apiClient.put(ENDPOINTS.closeBatch(batchId));
    return response;
  };

  public fetchCompetenciesList = async (batchId: number) => {
    const response = await this.apiClient.get(
      ENDPOINTS.fetchCompetenciesList(batchId)
    );
    const competenciesListModelData = deserializeJsonObjCollection(
      response,
      BatchCompetenciesModal,
      "fetchCompetenciesList"
    );
    return competenciesListModelData;
  };

  public updateCompetencyDate = async (
    batchId: number,
    competencyId: string,
    endDate: string,
    startDate?: string,
  ): Promise<string> => {
    const requestPayload = { endDate, startDate };
    const response = await this.apiClient.put(
      ENDPOINTS.updateCompetencyDate(batchId, competencyId),
      requestPayload
    );
    return response;
  };

  public getBatchDeliveryModels = async () => {
    const response = await this.apiClient.get(
      ENDPOINTS.fetchBatchDeliveryModels()
    );
    const batchDeliveryModelData = deserializeJsonObjCollection(
      response,
      BatchDeliveryModel,
      "getBatchDeliveryModels"
    );
    return batchDeliveryModelData;
  };

  public createBatch = async (
    payload: ICreateBatchPayload
  ): Promise<BatchModel> => {
    clearCacheForService([APIBaseServices.ORGANISATION_SERVICE]);
    const response = await this.apiClient.post(
      ENDPOINTS.createBatch(),
      payload
    );
    const createBatchModelData = deserializeJsonObj(
      response,
      BatchModel,
      "createBatch"
    ) as BatchModel;
    return createBatchModelData;
  };

  public fetchBatchesDetails = async (batchIds: number[]) => {
    const params = {
      batchIds,
    };

    const response = await this.apiClient.get(
      ENDPOINTS.fetchBatchesDetails(),
      params,
      true
    );

    const batchesDetails = deserializeJsonObjCollection(response.batches, BatchModel, 'fetchBatchesDetails') as BatchModel[];

    return batchesDetails;
  };


  public joinBatch = async (batchKey: string): Promise<IJoinBatchMessage> => {
    clearCacheForService([APIBaseServices.ORGANISATION_SERVICE]);
    clearCacheForService([APIBaseServices.PROFILE_SERVICE]);
    const params = {
      batchKey
    }
    const response = await this.apiClient.post(
      ENDPOINTS.joinBatch(),
      params
    );
    return response;
  };


  public fetchUserBatch = async (payload: IFetchUserBatchPayload): Promise<BatchModel[]> => {
    const params = {
      payload,
    };

    const response = await this.apiClient.get(
      ENDPOINTS.fetchUserBatch(payload.userId),
      params.payload
    );
    const userBatchModelData = deserializeJsonObjCollection(
      response,
      BatchModel,
      "fetchUserBatch"
    ) as BatchModel[];
    return userBatchModelData;
  };

  public fetchStudentAndFacultyCombinedBatch = async (payload: IFetchUserBatchPayload): Promise<BatchModel[]> => {
    const params = {
      payload,
    };

    const response = await this.apiClient.get(
      ENDPOINTS.fetchStudentAndFacultyCombinedBatch(payload.userId),
      params.payload
    );
    const studentFacultyBatchModelData = deserializeJsonObjCollection(
      response.batches,
      BatchModel,
      "fetchStudentAndFacultyCombinedBatch"
    ) as BatchModel[];
    return studentFacultyBatchModelData;
  };

  public fetchBatchesMembers = async (batchIds: string[], role: userRoles, search?: string): Promise<ProfileModel[]> => {
    const params = { batchIds, search: search ? search : '' };
    const userType = this.getUserType(role);
    const response = await this.apiClient.get(ENDPOINTS.fetchBatchMembers(userType), params);
    let memberProfleModelData = [];
    if (role === userRoles.student) {
      memberProfleModelData = deserializeJsonObjCollection(
        response.batchStudents,
        ProfileModel,
        "fetchBatchesMembers"
      ) as ProfileModel[];
    }
    if (role === userRoles.faculty) {
      memberProfleModelData = deserializeJsonObjCollection(
        response.batchFaculties,
        ProfileModel,
        "fetchBatchesMembers"
      ) as ProfileModel[];
    }
    if (role === userRoles.regionalManager) {
      memberProfleModelData = deserializeJsonObjCollection(
        response.batchRms,
        ProfileModel,
        "fetchBatchesMembers"
      ) as ProfileModel[];
    }
    return memberProfleModelData;
  }

  // all batches
  public fetchAllBatches = async (page?: number, limit?: number, search?: string, status?: string, courseId?: string, batchIds?: number[]): Promise<{ batches: BatchModel[]; total: number }> => {
    const response = await this.apiClient.get(ENDPOINTS.fetchAllBatches(), { page, limit, search, status, courseId, batchIds });
    const allBatches = deserializeJsonObjCollection(response.batches, BatchModel, 'fetchAllBatches') as BatchModel[];
    return { batches: allBatches, total: response.total }
  }

  // RM assigned batches
  public fetchRMAssignedBatches = async (regionalManagerId: string, page?: number, limit?: number, status?: BatchStatus): Promise<{ batches: RMBatchModel[]; total: number }> => {
    const response = await this.apiClient.get(ENDPOINTS.fetchRMAssignedBatches(), { regionalManagerId, page, limit, status }, true);
    const rmBatches = deserializeJsonObjCollection(response.data, RMBatchModel, 'fetchRMAssignedBatches') as RMBatchModel[];
    return { batches: rmBatches, total: response.total }
  }

  public updateBatch = async (batchId: string, payload: any) => {
    clearCacheForService([APIBaseServices.ORGANISATION_SERVICE]);
    const response = await this.apiClient.put(ENDPOINTS.updateBatch(batchId), payload);
    return deserializeJsonObj(response[0], BatchModel, 'updateBatch') as BatchModel;
  }

  public addFaculty = async (payload) => {
    const response = await this.apiClient.post(ENDPOINTS.addOrRemoveFaculty(), payload);
    return response;
  };

  public removeFaculty = async (payload) => {
    const response = await this.apiClient.delete(ENDPOINTS.addOrRemoveFaculty(), payload);
    return response;
  };

  public fetchUserBatchPermissionsByBatchId = async (batchId: string, userId: string) => {
    const response = await this.apiClient.get(ENDPOINTS.fetchUserBatchPermissionsByBatchId(batchId, userId));
    return response;
  }

  public getUserType = (role: string): string => {
    switch (role) {
      case userRoles.faculty:
        return 'faculties';
      case userRoles.student:
        return 'students';
      case userRoles.regionalManager:
        return 'RMs';
    }
  }
  public bulkUploadUsers = async (data:FormData) => {

    const response = await this.apiClient.post(ENDPOINTS.uploadStudents(),data);
    return response;
  }

  public invitedStudents = async (batchKey: string, page?: number, limit?: number, search?: string, status='INVITED') => {
    const params = {page, limit, search, status}
    const response = await this.apiClient.get(ENDPOINTS.invitedStudent(batchKey), params);
    return response;
  }

  public reSendOTP = async (emailIds: string[], batchKey: string, ccEmailIds?: string, instituteCustomUrl?: string) => {
    const params = { emailIds, ccEmailIds, batchKey, customUrl: instituteCustomUrl }
    const response = await this.apiClient.post(ENDPOINTS.resendOTP(),params);
    return response;
  }
  public deleteBatchStudents = async (batchId: number, studentId: string, deleteUserCourseData: boolean) => {
    const response = await this.apiClient.delete(ENDPOINTS.removeStudent(batchId, studentId));
    return response;
  }
  public invitedSingleStudents = async (batchKey: string, studentEmailIds: string) => {
    const params = {studentEmailIds}
    const response = await this.apiClient.post(ENDPOINTS.inviteSingleStudent(batchKey), params);
    return response;
  }

  public fetchBulkUploadStatusById = async (bulkUploadId: string) => {
    const response = await this.apiClient.get(ENDPOINTS.fetchBulkUploadStatusById(bulkUploadId))
    return response;
  }
}

const batchRepository = new BatchRepository(ApiClient);

export { batchRepository as BatchRepository };

// import { ObjectMapper } from "json-object-mapper";

// import { Logger } from "@utils/Logger";
// import { deserializeJsonObj, deserializeJsonObjCollection } from "@utils/ObjectMapperUtil";

// import { ICreateBatchPayload, IJoinBatchMessage, IJoinBatchPayload } from "@common/redux/batch/interfaces";

// import { ApiClient } from "@network/client";
// import { BatchModel } from "../models/Batch";
// import { BatchDeliveryModel } from "../models/BatchDeliveryModel";
// import { APIv1 } from "@common/network/constants";

// const ENDPOINTS = {
//   fetchBatches: () => `${APIv1}/organizationservice/batches`,
//   fetchBatchDetails: () => `${APIv1}/organizationservice/batches`,
//   fetchBatchMilestones: () => `${APIv1}/profileservice/batch-milestones`,
//   fetchBatchDeliveryModels: () => `${APIv1}/organizationservice/batchDeliveryModels`,
//   createBatch: () => `${APIv1}/organizationservice/batches`,
//   joinBatch: () => `${APIv1}/organizationservice/batches/join`,
//   fetchBatchByUUID: (batchUUID: string) => `${APIv1}/organizationservice/batches/${batchUUID}`,
// };

// class BatchRepository {
//   private apiClient: any;

//   constructor(apiClient: any) {
//     this.apiClient = apiClient;
//   }

//   public fetchBatches = async (status?: string, page?: number, limit?: number) => {
//     const params = {
//       status,
//       page,
//       limit
//     }
//     const response: any[] = await this.apiClient.get(ENDPOINTS.fetchBatches(), params);

//     const batchModelData = deserializeJsonObjCollection(response, BatchModel, 'fetchBatches') as BatchModel[];
//     return batchModelData;
//   };

//   public fetchBatchDetails = async (batchId: number) => {
//     const params = {
//       batchId: batchId
//     };

//     const response = await this.apiClient.get(
//       ENDPOINTS.fetchBatchDetails(), params
//     );

//     const batch = ObjectMapper.deserialize(BatchModel, response[0]);

//     return batch;
//   };

//   // Don't refactor fetchBatchMilestones, as the reponse is not actual milestones but mappings.
//   public fetchBatchMilestones = async (batchId: number)  => {

//     const params = {
//       batchId: batchId
//     };

//     const response = await this.apiClient.get(ENDPOINTS.fetchBatchMilestones(), params);
//     const batchMilestones: any[] = response.batchMilestones
//     return batchMilestones;
//   };

//   public getBatchDeliveryModels = async () => {
//     const response = await this.apiClient.get(ENDPOINTS.fetchBatchDeliveryModels());
//     const batchDeliveryModelData = response.map((model: BatchDeliveryModel) => {
//       try {
//         const batchDeliveryModel = ObjectMapper.deserialize(BatchDeliveryModel, model);
//         return batchDeliveryModel;
//       } catch (e) {
//         Logger.warn('error message after deserialize', { error: e.message});
//       }
//     }).filter((model: BatchDeliveryModel) => model !== undefined);
//     return batchDeliveryModelData;
//   };

//   public createBatch = async (payload: ICreateBatchPayload): Promise<BatchModel> => {
//     const response = await this.apiClient.post(ENDPOINTS.createBatch(), payload);
//     const createBatchModelData = deserializeJsonObj(response, BatchModel, 'createBatch') as BatchModel;
//     return createBatchModelData;
//   };

//   public joinBatch = async (payload: IJoinBatchPayload): Promise<IJoinBatchMessage> => {
//     const response = await this.apiClient.post(ENDPOINTS.joinBatch(), payload);
//     return response;
//   };

//   public fetchBatchByUUID = async (batchUUID: string): Promise<BatchModel>=> {
//     const response = await this.apiClient.get(ENDPOINTS.fetchBatchByUUID(batchUUID));
//     const batchModelData = deserializeJsonObj(response, BatchModel, 'fetchBatchByUUID') as BatchModel;
//     return response;
//   };

// }

// const batchRepository = new BatchRepository(ApiClient);

// export { batchRepository as BatchRepository };
