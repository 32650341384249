import { CourseModel } from "@common/domain/models/Course";
import { Institute } from "@common/domain/models/Institute";
import { ProfileModel } from "@common/domain/models/Profile";

import { IFluxStandardAction } from "@store/webInterfaces";

const actionTypePrefix = "INSTITUTES";

export const institutesActions = {
  FETCH_INSTITUTES: `${actionTypePrefix}/FETCH_INSTITUTES`,
  FETCH_INSTITUTES_SUCCESS: `${actionTypePrefix}/FETCH_INSTITUTES_SUCCESS`,
  FETCH_INSTITUTES_FAIL: `${actionTypePrefix}/FETCH_INSTITUTES_FAIL`,
  
  FETCH_CERTIFIED_COURSES: `${actionTypePrefix}/FETCH_CERTIFIED_COURSES`,
  FETCH_CERTIFIED_COURSES_SUCCESS: `${actionTypePrefix}/FETCH_CERTIFIED_COURSES_SUCCESS`,
  FETCH_CERTIFIED_COURSES_FAIL: `${actionTypePrefix}/FETCH_CERTIFIED_COURSES_FAIL`,

  FETCH_MULTIPLE_INSTITUTES_DETAIL: `${actionTypePrefix}/FETCH_MULTIPLE_INSTITUTES_DETAIL`,
  FETCH_MULTIPLE_INSTITUTES_DETAIL_SUCCESS: `${actionTypePrefix}/FETCH_MULTIPLE_INSTITUTES_DETAIL_SUCCESS`,
  FETCH_MULTIPLE_INSTITUTES_DETAIL_FAIL: `${actionTypePrefix}/FETCH_MULTIPLE_INSTITUTES_DETAIL_FAIL`,

  FETCH_INSTITUTE_FACULTIES: `${actionTypePrefix}/FETCH_INSTITUTE_FACULTIES`,
  FETCH_INSTITUTE_FACULTIES_SUCCESS: `${actionTypePrefix}/FETCH_INSTITUTE_FACULTIES_SUCCESS`,
  FETCH_INSTITUTE_FACULTIES_FAIL: `${actionTypePrefix}/FETCH_INSTITUTE_FACULTIES_FAIL`,

  FETCH_USER_INSTITUTE: `${actionTypePrefix}/FETCH_USER_INSTITUTE`,
  FETCH_USER_INSTITUTE_SUCCESS: `${actionTypePrefix}/FETCH_USER_INSTITUTE_SUCCESS`,
  FETCH_USER_INSTITUTE_FAIL: `${actionTypePrefix}/FETCH_USER_INSTITUTE_FAIL`,
};

export interface IFetchInstitutesAction {
  type: string;
  payload?: {
    instituteId: string;
    facultyId: string,
    status: string,
    courseRootIds?: string[]
  };
}

export const fetchInstitutes = (): IFetchInstitutesAction => {
  return {
    type: institutesActions.FETCH_INSTITUTES,
  };
};

export const fetchInstitutesSuccess = (institutes: any) => {
  return {
    type: institutesActions.FETCH_INSTITUTES_SUCCESS,
    payload: institutes,
  };
};

export const fetchInstitutesFailure = (error: string) => {
  return {
    type: institutesActions.FETCH_INSTITUTES_FAIL,
    payload: error,
  };
};

export const fetchCertifiedCourses = (
  instituteId: string,
  facultyId: string,
  status: string,
  courseRootIds?: string[]
): IFetchInstitutesAction => {
  return {
    type: institutesActions.FETCH_CERTIFIED_COURSES,
    payload: {
      instituteId,
      facultyId,
      status, 
      courseRootIds
    },
  };
};

export const fetchCertifiedCoursesSuccess = (courses) => {
  return {
    type: institutesActions.FETCH_CERTIFIED_COURSES_SUCCESS,
    payload: courses,
  };
};

export const fetchCertifiedCoursesFailure = (error: string) => {
  return {
    type: institutesActions.FETCH_CERTIFIED_COURSES_FAIL,
    payload: error,
  };
};

// multiple institute detail
export const fetchMultipleInstitutes = (instituteIds: number[]): IFluxStandardAction<number[]> => {
  return {
    type: institutesActions.FETCH_MULTIPLE_INSTITUTES_DETAIL,
    payload: instituteIds
  }
}

export const fetchMultipleInstitutesSuccess = (payload: Institute[]): IFluxStandardAction<Institute[]> => {
  return {
    type: institutesActions.FETCH_MULTIPLE_INSTITUTES_DETAIL_SUCCESS,
    payload
  }
}

export const fetchMultipleInstitutesFail = (error: string): IFluxStandardAction<string> => {
  return {
    type: institutesActions.FETCH_MULTIPLE_INSTITUTES_DETAIL_FAIL,
    payload: error
  }
}

export interface IFetchInstituteFacultiesAction {
  type: string;
  payload: {
    instituteId: string;
    courseId: string;
  };
}

export const fetchInstituteFaculties = (instituteId: string, courseId: string): IFetchInstituteFacultiesAction => {
  return {
    type: institutesActions.FETCH_INSTITUTE_FACULTIES,
    payload: {
      instituteId,
      courseId
    },
  };
};

export const fetchInstituteFacultiesSuccess = (faculties: ProfileModel[]): IFluxStandardAction<ProfileModel[]> => {
  return {
    type: institutesActions.FETCH_INSTITUTE_FACULTIES_SUCCESS,
    payload: faculties,
  };
};

export const fetchInstituteFacultiesFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: institutesActions.FETCH_INSTITUTE_FACULTIES_FAIL,
    payload: error,
  };
};

// fetch user institute
export const fetchUserInstitute = (payload) => {
  return {
    type: institutesActions.FETCH_USER_INSTITUTE,
    payload
  };
};

export const fetchUserInstituteSuccess = (payload) => {
  return {
    type: institutesActions.FETCH_USER_INSTITUTE_SUCCESS,
    payload: payload,
  };
};

export const fetchUserInstituteFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: institutesActions.FETCH_USER_INSTITUTE_FAIL,
    payload: error,
  };
};

