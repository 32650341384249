
import { ProfileModel } from "@common/domain/models/Profile";

import { userRoles } from "@constants/config";

export interface IIncompleteField {
  fieldName: string;
  message: string;
}

export type FieldVisibilityMap = { [fieldName: string]: boolean };


export const USER_FIELD_NAMES = Object.freeze({
  profilePic: "profilePic",
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  language: "language",
  dob: "dob",
  gender: "gender",
  education: "education",
  phone: "phone",
  linkedIn: "linkedIn",
  bio: "bio",
  location: "location",
  government: "government",
  sector: "sector"
});



  /**
     * 
        common required fields 
            firstName
            lastName
            cityName
            phone.countryCode
            phone.number
            language

        For Students
            birthYear 
            gender
            education
  * 
  */


  type ProfileField = {
    fieldName: string;
    fieldData: any;
    message: string;
  }

const requiredCommonFields = (profile: ProfileModel): ProfileField[] => {
  let requiredFields: ProfileField[] = [
    {
      fieldName: USER_FIELD_NAMES.firstName,
      fieldData: profile.getFirstName(),
      message: '',
    },
    {
      fieldName: USER_FIELD_NAMES.lastName,
      fieldData: profile.getLastname(),
      message: '',
    },
    {
      fieldName: USER_FIELD_NAMES.location,
      fieldData: profile?.getLocation && profile?.getLocation() && (profile?.getLocation()?.city || profile?.getLocation()?.country),
      message: '',
    },
    {
      fieldName: USER_FIELD_NAMES.language,
      fieldData: profile.getPreferredLanguage(),
      message: '',
    }
  ];
  if (profile.getRole() === userRoles.student) {
    requiredFields = [...requiredFields,
    {
      fieldName: USER_FIELD_NAMES.phone,
      fieldData: profile?.getPhoneNumber(),
      message: '',
    },
    {
      fieldName: USER_FIELD_NAMES.government,
      fieldData: profile?.getGovernmentBody(),
      message: '',
    },
    {
      fieldName: USER_FIELD_NAMES.sector,
      fieldData: profile?.getSector(),
      message: '',
    }
    ]
  }
  return requiredFields;
}

export const getRequiredIncompleteFields = (
  profile: ProfileModel
): {
  incompleteFields: IIncompleteField[];
  visibilityMap: FieldVisibilityMap;
} => {
  const incompleteFields: IIncompleteField[] = [];
  const visibilityMap: FieldVisibilityMap = {};
  const requiredFields = requiredCommonFields(profile);

  requiredFields.forEach((field: ProfileField) => {
    if (!field.fieldData) {
      incompleteFields.push({
        fieldName: field.fieldName,
        message: field.message,
      });
      visibilityMap[field.fieldName] = true;
    }

  })

  return { incompleteFields, visibilityMap };
};

