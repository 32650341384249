import { i18n } from '@translations/i18n';

import { ApiClientError } from '@network/apiClientError';
import PhoneValid, { PhoneResult } from 'phone';
import { DateHelper } from './DateHelper';
import dayjs from 'dayjs';

export type CountryPhoneInputValue = {
    code?: number;
    phone?: string;
    short?: string;
};

const validate = (getValidationSchema: any): any => (values: any[]) => {
    const validationSchema = getValidationSchema(values);
    try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
    } catch (error) {
        return getErrorsFromValidationError(error);
    }
};

const getErrorsFromValidationError = (validationError: any) => validationError.inner.reduce((errors: any[], error: any) => ({
    ...errors,
    [error.path]: error.errors[0] || '...'
}), {});

const validatePhone = (text: string): boolean => {
    if (/^\d{10}$/.test(text)) {
        return true;
    }
    return false;
};

const formatServerErrorsToFormErrors = (error: ApiClientError): any => {
    const errorDetails = error.details;
    if (!errorDetails) {
        return {};
    }

    const fieldErrors = errorDetails.errors || [];
    return fieldErrors.reduce((errorObject: any, err: any) => {
        errorObject[err.field] = err.reason;
        return errorObject;
    }, {});
};

const validateEmail = (email: string) => {
    const regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]+)$/;

    return regex.test(email);
};

const validateEmptyData = (data: string) => data !== '';

export const isValidNumber = (value: any): boolean => (
    isFinite(value)
    && countDecimals(value) <= 6
    && parseFloat(value) < Number.MAX_SAFE_INTEGER
);

const countDecimals = (value: any): number => {
    if (value % 1 !== 0) {
        return value.toString().split('.')[1].length;
    }
    return 0;
};

export const isBlankOrUndefined = (str: string | undefined) => !str || /^\s*$/.test(str);

export const isURL = (url: string): boolean => {
    const regex =
    '(http(s)?:\\/\\/.)(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&\\/=]*)';
    const matched = url.trim().match(regex);
    if (matched && matched.length > 0) {
    // for prefect match
        if (matched[0] === url) {
            return true;
        }
    }
    return false;
};

export const validatePassword = (password: string) => {
    const regex = new RegExp('^(?=.*[A-Za-z])(?=.*[0-9])(?=.{8,})');
    return regex.test(password);
};

export const isValidPhoneNumber = (number: string): boolean => number && number.length >= 8 && number.length <= 15;

export const disablePastDates = (current) : boolean => {
    return !DateHelper.isCurrentDate(current) && current < dayjs().startOf('day');
}

export const disableFutureDates = (current) : boolean => {
    return !DateHelper.isCurrentDate(current) && current > dayjs().startOf('day');
}

export const isLongName = (firstName: string, lastName: string) : boolean => {
    return ((firstName?.indexOf(' ') <= 0 && firstName?.length > 11) ||
        (lastName?.indexOf(' ') <= 0 && lastName?.length > 11));
}

export const PhoneNumberValidation = (value: CountryPhoneInputValue): string => {
    const phoneValid = PhoneValid(value.phone,  { country: value.short }); 
    const result = value.phone.replace(/[^\w\s]/gi, '')
    if (!phoneValid.isValid ||
         (phoneValid.isValid && `+${result}` === phoneValid.phoneNumber)) {
        return i18n.t('global.messages.INVALID_PHONE_NUMBER');
    }
    return "";
}

const checkUploadFileType = (file, type) => {
    return file && file['type'].split('/')[0] === type;
}

export const restrictEmojis = (name: string) => {
    const EMOJI_REGEX = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

    return !EMOJI_REGEX.test(name);
}

export const FormHelper = {
  validate,
  validatePhone,
  getErrorsFromValidationError,
  formatServerErrorsToFormErrors,
  validateEmail,
  validateEmptyData,
  isBlankOrUndefined,
  isValidNumber,
  isURL,
  validatePassword,
  isValidPhoneNumber,
  disablePastDates,
  isLongName,
  PhoneNumberValidation,
  checkUploadFileType,
  restrictEmojis,
  disableFutureDates
};
