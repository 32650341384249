import { IDepartment, IProfile, IUserEducationQualification, ProfileModel } from "@models/Profile";

import { profileActions } from "./actions";

export interface IProfileState {
  byId: {};
  isLoggedInUserProfileIncomplete: boolean;
  fetchLoggedInUser: IProfile;
  profiles: ProfileModel[];
  createUser: any;
  educationQualifications: IUserEducationQualification[];
  departments: IDepartment[];
  profilebyEmail: ProfileModel;
  loaders: {
    fetchProfiles: boolean;
    fetchLoggedInUser: boolean;
    createUser: boolean;
    updateProfile: boolean;
    educationQualifications: boolean;
    profilebyEmail: boolean;
    fetchDepartments: boolean;
    mapUserInvitation: boolean;
  };
  errors: {
    fetchProfiles: string;
    fetchLoggedInUser: string;
    createUser: string;
    updateProfile: string;
    educationQualifications: string;
    profilebyEmail: string;
    fetchDepartments: string;
    mapUserInvitation: string;
  };
}

export const initialState: IProfileState = {
  byId: {},
  isLoggedInUserProfileIncomplete: false,
  fetchLoggedInUser: {} as IProfile,
  profiles: [],
  createUser: {},
  educationQualifications: [],
  departments: [],
  profilebyEmail: {} as ProfileModel,
  loaders: {
    fetchProfiles: false,
    fetchLoggedInUser: false,
    createUser: false,
    updateProfile: false,
    educationQualifications: false,
    profilebyEmail: false,
    fetchDepartments: false,
    mapUserInvitation:false,
  },
  errors: {
    fetchProfiles: "",
    fetchLoggedInUser: "",
    createUser: "",
    updateProfile: "",
    educationQualifications: "",
    profilebyEmail: "",
    fetchDepartments: "",
    mapUserInvitation:"",
  },
};

let profileMapUpdates = null;
export const profileReducer = (
  state: IProfileState = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {

    case profileActions.SHOW_PROFILE_MODAL:
      return {
        ...state,
        isLoggedInUserProfileIncomplete: action.payload.isVisible
      };
      
    case profileActions.FETCH_PROFILES_BY_USER_IDS:
      return {
        ...state,
        loaders: { ...state.loaders, fetchProfiles: true },
        errors: { ...state.errors, fetchProfiles: "" },
      };
    case profileActions.FETCH_PROFILES_BY_USER_IDS_SUCCESS:
      let profiles = action.payload.profiles;
      profileMapUpdates = updateProfileMap(profiles);
      return {
        ...state,
        byId: { ...state.byId, ...profileMapUpdates },
        profiles,
        loaders: { ...state.loaders, fetchProfiles: false },
      };
    case profileActions.FETCH_PROFILES_BY_USER_IDS_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchProfiles: false },
        errors: { ...state.errors, fetchProfiles: action.payload },
      };

    // fetching the logged in user details.
    case profileActions.FETCH_PROFILE_DETAILS:
      return {
        ...state,
        loaders: { ...state.loaders, fetchLoggedInUser: true },
        errors: { ...state.errors, fetchLoggedInUser: "" },
      };
    case profileActions.FETCH_PROFILE_DETAILS_SUCCESS:
      profileMapUpdates = updateProfileMap([action.payload]);
      return {
        ...state,
        byId: { ...state.byId, ...profileMapUpdates },
        fetchLoggedInUser: action.payload,
        loaders: { ...state.loaders, fetchLoggedInUser: false },
      };
    case profileActions.FETCH_PROFILE_DETAILS_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchLoggedInUser: false },
        errors: { ...state.errors, fetchLoggedInUser: action.payload },
      };

    // Register New User
    case profileActions.CREATE_PROFILE:
      return {
        ...state,
        loaders: { ...state.loaders, createUser: true },
        errors: { ...state.errors, createUser: "" },
      };
    case profileActions.CREATE_PROFILE_SUCCESS:
      return {
        ...state,
        createUser: action.payload,
        loaders: { ...state.loaders, createUser: false },
      };
    case profileActions.CREATE_PROFILE_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, createUser: false },
        errors: { ...state.errors, createUser: action.payload },
      };

    case profileActions.UPDATE_PROFILE_DETAILS:
      return {
        ...state,
        loaders: { ...state.loaders, updateProfile: true },
        errors: { ...state.errors, updateProfile: "" },
      };
    case profileActions.UPDATE_PROFILE_DETAILS_SUCCESS:
      profileMapUpdates = updateProfileMap([action.payload]);
      return {
        ...state,
        fetchLoggedInUser: action.payload,
        byId: { ...state.byId, ...profileMapUpdates },
        createUser: action.payload,
        loaders: { ...state.loaders, updateProfile: false },
      };
    case profileActions.UPDATE_PROFILE_DETAILS_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, updateProfile: false },
        errors: { ...state.errors, updateProfile: action.payload },
      };


    case profileActions.FETCH_EDUCATION_QUALIFICATION:
      return {
        ...state,
        loaders: { ...state.loaders, educationQualifications: true },
        errors: { ...state.errors, educationQualifications: "" },
      };
    case profileActions.FETCH_EDUCATION_QUALIFICATION_SUCCESS:
      return {
        ...state,
        educationQualifications: action.payload,
        loaders: { ...state.loaders, educationQualifications: false },
      };
    case profileActions.FETCH_EDUCATION_QUALIFICATION_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, educationQualifications: false },
        errors: { ...state.errors, educationQualifications: action.payload },
      };

    case profileActions.FETCH_PROFILES_BY_EMAIL_ID:
      return {
        ...state,
        loaders: { ...state.loaders, profilebyEmail: true },
        errors: { ...state.errors, profilebyEmail: "" }
      };
    case profileActions.FETCH_PROFILES_BY_EMAIL_ID_SUCCESS:
      return {
        ...state,
        profilebyEmail: action.payload,
        loaders: { ...state.loaders, profilebyEmail: false }
      };
    case profileActions.FETCH_PROFILES_BY_EMAIL_ID_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, profilebyEmail: false },
        errors: { ...state.errors, profilebyEmail: action.payload }
      }

    case profileActions.FETCH_DEPARTMENTS:
      return {
        ...state,
        loaders: { ...state.loaders, fetchDepartments: true },
        errors: { ...state.errors, fetchDepartments: "" }
      };
    case profileActions.FETCH_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: action.payload,
        loaders: { ...state.loaders, fetchDepartments: false }
      };
    case profileActions.FETCH_DEPARTMENTS_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchDepartments: false },
        errors: { ...state.errors, fetchDepartments: action.payload }
      }
    case profileActions.MAP_USER_INVITATION:
      return {
        ...state,
        loaders: { ...state.loaders, mapUserInvitation: true },
        errors: { ...state.errors, mapUserInvitation: "" }
      }
    case profileActions.MAP_USER_INVITATION_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, mapUserInvitation: false },
        errors: { ...state.errors, mapUserInvitation: ""}
      }
    case profileActions.MAP_USER_INVITATION_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, mapUserInvitation: false },
        errors: { ...state.errors, mapUserInvitation: action.payload }
      }  
    default:
      return state;
  }
};

export const updateProfileMap = (newProfiles: IProfile[]) => {
  const newProfilesMap = {};
  newProfiles.forEach((profile) => {
    newProfilesMap[profile.userId] = profile.id;
    newProfilesMap[profile.id] = profile;
  });
  return newProfilesMap;
};
