export const profileMessages = {
    profileInfoTitle: {
        key: 'profile.labelsAndTitles.PROFILE_INFORMATION'
    },
    firstName: {
        key: 'profile.labelsAndTitles.FIRST_NAME'
    },
    lastName: {
        key: 'profile.labelsAndTitles.LAST_NAME'
    },
    location: {
        key: 'profile.labelsAndTitles.LOCATION'
    },
    bio: {
        key: 'profile.labelsAndTitles.BIO'
    },
    linkedIn: {
        key: 'profile.labelsAndTitles.LINKEDIN_PROFILE'
    },
    myCalendly: {
        key: 'profile.labelsAndTitles.MY_CALENDLY_URL'
    },
    calendlyLink: {
        key: 'profile.labelsAndTitles.CALENDLY_LINK'
    },
    calendlyPlaceholder: {
        key: 'profile.labelsAndTitles.CALENDLY_LINK_PLACEHOLDER'
    },
    typeHere: {
        key: 'profile.labelsAndTitles.TYPE_HERE'
    },
    bioPlaceholder: {
        key: 'profile.labelsAndTitles.BIO_PLACEHOLDER'
    },
    linedInPlaceholder: {
        key: 'profile.labelsAndTitles.LINKEDIN_PLACEHOLDER'
    },
    chat: {
        key: 'global.actions.CHAT'
    },
    bookAppointment: {
        key: 'profile.actions.BOOK_APPOINTMENT'
    },
    uploadPicture: {
        key: 'profile.actions.UPLOAD_PICTURE'
    },
    cancel: {
        key: 'global.actions.CANCEL'
    },
    save: {
        key: 'global.actions.SAVE'
    },
    linkedInProfile: {
        key: 'profile.actions.LINKEDIN_PROFILE'
    },
    profileSubtext: {
        key: 'profile.messages.PROFILE_SUBTEXT'
    },
    calendlyDesc: {
        key: 'profile.messages.CALENDLY_DESC'
    },
    somethingWrong: {
        key: 'API_ERRORS.ERR',
    },
    profileUpdated: {
        key: 'profile.messages.PROFILE_UPDATE_SUCCESS'
    },
    imageUploadFailed: {
        key: 'global.messages.IMAGE_UPLOAD_FAILED'
    },
     linkedInUrlError: {
        key: 'profile.errors.LINKED_IN_URL_ERROR'
    },
    central: {
        key: 'global.labelsAndTitles.CENTRAL'
    },
    state: {
        key: 'global.labelsAndTitles.STATE'
    },
    profilePicUpdate: {
        key: 'profile.messages.PROFILE_PIC_UPDATE_SUCCESS'
    },
      profileCompleteModalTitle: {
        key: 'profile.labelsAndTitles.PROFILE_COMPLETE_MODAL_TITLE'
    },
    calendlyUrlError: {
        key: "profile.errors.CALENDLY_URL_ERROR"
    },
    organisationId: {
        key: 'profile.labelsAndTitles.ORGANISATION_ID'
    },
    organisationIdPlaceholder: {
        key: 'profile.labelsAndTitles.ORGANISATION_ID_PLACEHOLDER'
    },
    facultyRole: {
        key: 'profile.labelsAndTitles.FACULTY_ROLE'
    },
    rmRole: {
        key: 'profile.labelsAndTitles.RM_ROLE'
    },
    studentRole: {
        key: 'profile.labelsAndTitles.STUDENT_ROLE'
    },
    changePicture: {
        key: 'profile.actions.CHANGE_PICTURE'
    },
};
