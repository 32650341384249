import { JsonProperty } from "json-object-mapper";
import { ProfileModel } from "./Profile";

export interface ISpeakers {
    firstName: string;
    lastName: string;
    email: string;
    bio: string;
    userId: string;
}

interface IEventImage {
  link: string;
  mediaType: string;
  _id: string;
}

interface IStateDateTime {
  datetime: any;
}
export class EventModel {
  @JsonProperty({ name: "_id", required: false })
  private id: string = "";

  @JsonProperty({ name: "title", required: true })
  private title: string = "";

  @JsonProperty({ name: "description", required: false })
  private description: string = "";

  @JsonProperty({ name: "start", required: false })
  private start: IStateDateTime = null;

  @JsonProperty({ name: "end", required: false })
  private end: IStateDateTime = null;

  @JsonProperty({ name: "duration", required: false })
  private duration: string = "";

  @JsonProperty({ name: "timeZone", required: false })
  private timeZone: string = "";

  @JsonProperty({ name: "eventLink", required: false })
  private eventLink: string = "";

  @JsonProperty({ name: "provider", required: false })
  private provider: string = "";

  @JsonProperty({ name: "status", required: false })
  private status: string = "";

  @JsonProperty({ name: "clientName", required: false })
  private clientName: string = "";

  @JsonProperty({ name: "participantsLimit", required: false })
  private participantsLimit: number = 0;

  @JsonProperty({ name: "organizer", required: false })
  private organizer: string = "";

  @JsonProperty({ name: "speakers", required: false })
  private speakers: ISpeakers[] = [];

  @JsonProperty({ name: "eventImage", required: false })
  private eventImage: IEventImage[] = [];

  @JsonProperty({ name: "enrolledUsers", required: false })
  private enrolledUsers: number = 0;

  @JsonProperty({ name: "registeredUsers", required: false })
  private registeredUsers: string[] = [];

  @JsonProperty({ name: "attendeesList", required: false })
  private attendeesList: ProfileModel[] = [];

  @JsonProperty({ name: "registrationStatus", required: false })
  private registrationStatus: string = "";

  @JsonProperty({ name: "meetingLink", required: false })
  private meetingLink: string = "";

  public getRegisteredUsers() {
    return this.registeredUsers;
  }

  public getAttendeesList() {
    return this.attendeesList;
  }

  public setAttendeesList(users) {
    this.attendeesList = users;
  }

  public setRegisteredUsers(users) {
    this.registeredUsers = users;
  }

  public setOrganisers(user) {
    this.organizer = user;
  }

  public getEventImage(): IEventImage[] {
    return this.eventImage;
  }

  public getId(): string {
    return this.id;
  }

  public getTitle(): string {
    return this.title;
  }

  public getEnrolledUsers(): number {
    return this.enrolledUsers;
  }

  public getRegistrationStatus(): string {
    return this.registrationStatus;
  }

  public getMeetingLink(): string {
    return this.meetingLink;
  }


  public getDescription(): string {
    return this.description;
  }

  public getStartDateTime(): IStateDateTime {
    return this.start;
  }

  public getEndDateTime(): IStateDateTime {
    return this.end;
  }

  public getDuration(): string {
    return this.duration;
  }

  public getTimeZone(): string {
    return this.timeZone;
  }

  public getEventLink(): string {
    return this.eventLink;
  }

  public getProvider(): string {
    return this.provider;
  }

  public getStatus(): string {
    return this.status;
  }

  public getClientName(): string {
    return this.clientName;
  }

  public getParticipantsLimit(): number {
    return this.participantsLimit;
  }

  public getOrganizer(): string {
    return this.organizer;
  }

  public getSpeakers(): ISpeakers[] {
    return this.speakers;
  }
}

