import {
  Conversation,
  DefaultPrompts,
  IAIFeedback,
  Prompt,
  PromptMetaData,
  PromptPayload,
} from "@common/domain/models/AICoachModel";

const actionTypePrefix = "AICOACH";

export const aiCoachActions = {
  FETCH_PROMPTS: `${actionTypePrefix}/FETCH_PROMPTS`,
  FETCH_PROMPTS_SUCCESS: `${actionTypePrefix}/FETCH_PROMPTS_SUCCESS`,
  FETCH_PROMPTS_FAIL: `${actionTypePrefix}/FETCH_PROMPTS_FAIL`,

  FETCH_CONVERSATION: `${actionTypePrefix}/FETCH_CONVERSATION`,
  FETCH_CONVERSATION_SUCCESS: `${actionTypePrefix}/FETCH_CONVERSATION_SUCCESS`,
  FETCH_CONVERSATION_FAIL: `${actionTypePrefix}/FETCH_CONVERSATION_FAIL`,

  DELETE_CONVERSATION: `${actionTypePrefix}/DELETE_CONVERSATION`,
  DELETE_CONVERSATION_SUCCESS: `${actionTypePrefix}/DELETE_CONVERSATION_SUCCESS`,
  DELETE_CONVERSATION_FAIL: `${actionTypePrefix}/DELETE_CONVERSATION_FAIL`,

  GET_PROMPT_METADATA: `${actionTypePrefix}/GET_PROMPT_METADATA`,
  GET_PROMPT_METADATA_SUCCESS: `${actionTypePrefix}/GET_PROMPT_METADATA_SUCCESS`,
  GET_PROMPT_METADATA_FAIL: `${actionTypePrefix}/GET_PROMPT_METADATA_FAIL`,

  FETCH_JOB_ROLE: `${actionTypePrefix}/FETCH_JOB_ROLE`,
  FETCH_JOB_ROLE_SUCCESS: `${actionTypePrefix}/FETCH_JOB_ROLE_SUCCESS`,
  FETCH_JOB_ROLE_FAIL: `${actionTypePrefix}/FETCH_JOB_ROLE_FAIL`,

  RESET_CONVERSATION: `${actionTypePrefix}/RESET_CONVERSATION`,
  RESET_CONVERSATION_SUCCESS: `${actionTypePrefix}/RESET_CONVERSATION_SUCCESS`,
  RESET_CONVERSATION_FAIL: `${actionTypePrefix}/RESET_CONVERSATION_FAIL`,

  USER_FEEDBACK: `${actionTypePrefix}/USER_FEEDBACK`,
  USER_FEEDBACK_SUCCESS: `${actionTypePrefix}/USER_FEEDBACK_SUCCESS`,
  USER_FEEDBACK_FAIL: `${actionTypePrefix}/USER_FEEDBACK_FAIL`,

};

export interface SendPropmtPayload {
  entityId: string;
  prompt: string;
  promptType: string;
  description?: string;
  visibleText?: string;
  userInput?: string;
}

export interface IFetchPropmtsAction {
  type: string;
  payload: PromptPayload
}

export interface IFetchConversationAction {
  type: string;
  payload: {
    entityId: string;
    page?: number;
    limit?: number;
    tags?: any;
  };
}

export interface ISendPropmtsAction {
  type: string;
  payload: SendPropmtPayload;
}

export interface IDeleteConversationAction {
  type: string;
  payload: {
    entityId: string
  };
}

export interface IFetchPromptMetadataAction {
  type: string;
  payload: {
    pageNumber?: number;
    limit?: number;
    keys?: string[];
  };
}

export interface IFetchJobRolesAction {
  type: string;
  payload: string
}
export interface IUserFeedbackAction {
  type: string;
  payload: IAIFeedback;
}

// fetch prompts for the given entity id(lesson)
export const fetchPropmts = (payload: PromptPayload): IFetchPropmtsAction => {
  return {
    type: aiCoachActions.FETCH_PROMPTS,
    payload,
  };
};

export const fetchPropmtsSuccess = (defaultPrompts: DefaultPrompts[]) => {
  return {
    type: aiCoachActions.FETCH_PROMPTS_SUCCESS,
    payload: defaultPrompts,
  };
};

export const fetchPropmtsFail = (error: string) => {
  return {
    type: aiCoachActions.FETCH_PROMPTS_FAIL,
    payload: error,
  };
};

// get user converation by entity id(lesson id)

export const fetchConversation = (
  entityId: string,
  page?: number,
  limit?: number,
  tags?: any
): IFetchConversationAction => {
  return {
    type: aiCoachActions.FETCH_CONVERSATION,
    payload: { entityId, page, limit, tags },
  };
};

export const fetchConversationSuccess = (page: number, conversation: Conversation[], totalCount: number) => {
  return {
    type: aiCoachActions.FETCH_CONVERSATION_SUCCESS,
    payload: {page, conversation, totalCount},
  };
};

export const fetchConversationFail = (error: string) => {
  return {
    type: aiCoachActions.FETCH_CONVERSATION_FAIL,
    payload: error,
  };
};


// delete user converation by entity id(lesson id)

export const deleteConversation = (entityId: string): IDeleteConversationAction => {
  return {
    type: aiCoachActions.DELETE_CONVERSATION,
    payload: { entityId },
  };
};

export const deleteConversationSuccess = (success: any) => {
  return {
    type: aiCoachActions.DELETE_CONVERSATION_SUCCESS,
    payload: success,
  };
};

export const deleteConversationFail = (error: string) => {
  return {
    type: aiCoachActions.DELETE_CONVERSATION_FAIL,
    payload: error,
  };
};

// fetch prompts for the given entity id(lesson)
export const fetchPropmtsMetadata = (pageNumber: number, limit: number, keys: string[]): IFetchPromptMetadataAction => {
  return {
    type: aiCoachActions.GET_PROMPT_METADATA,
    payload: {
      pageNumber, limit, keys
    }
  };
};

export const fetchPropmtsMetadataSuccess = (defaultPrompts: PromptMetaData) => {
  return {
    type: aiCoachActions.GET_PROMPT_METADATA_SUCCESS,
    payload: defaultPrompts,
  };
};

export const fetchPropmtsMetadataFail = (error: string) => {
  return {
    type: aiCoachActions.GET_PROMPT_METADATA_FAIL,
    payload: error,
  };
};
// fetch prompts for the given entity id(lesson)
export const fetchJobRoles = (payload: any): IFetchJobRolesAction => {
  return {
    type: aiCoachActions.FETCH_JOB_ROLE,
    payload,
  };
};

export const fetchJobRolesSuccess = (defaultPrompts: DefaultPrompts[]) => {
  return {
    type: aiCoachActions.FETCH_JOB_ROLE_SUCCESS,
    payload: defaultPrompts,
  };
};

export const fetchJobRolesFail = (error: string) => {
  return {
    type: aiCoachActions.FETCH_JOB_ROLE_FAIL,
    payload: error,
  };
};

export const resetConversation = () => {
  return {
    type: aiCoachActions.RESET_CONVERSATION,
  }
}

export const sendUserFeedback = (payload: IAIFeedback): IUserFeedbackAction => {
  return {
    type: aiCoachActions.USER_FEEDBACK,
    payload,
  };
};

export const sendUserFeedbackSuccess = (payload: IAIFeedback): IUserFeedbackAction => {
  return {
    type: aiCoachActions.USER_FEEDBACK_SUCCESS,
    payload,
  };
};

export const sendUserFeedbackFail = (error: string) => {
  return {
    type: aiCoachActions.USER_FEEDBACK_FAIL,
    payload: error,
  }
}
