import React, { Component } from 'react';
import Img from 'react-cool-img';

import { Avatar } from 'antd';

import { DataMappingUtils } from '@utils/DataMappingUtils';

import { Options, Retry } from '@components/Image';

interface IProps {
    imageUrl: string;
    name: string;
    className?: string;
    initialsClassName?: string;
    placeholder?: string;
    error?: string;
    decode?: boolean;
    lazy?: boolean;
    cache?: boolean;
    debounce?: number;
    observerOptions?: Options;
    retry?: Retry;
    srcSet?: string;
    sizes?: string;
    onError?: (event: any) => void;
    onLoad?: (event: any) => void;
    size?: number | "small" | "large" | "default" | undefined;
    shape?: 'circle' | 'square';
    alt?: string;
}

interface IState {
    showInitials: boolean;
}

export default class ProfilePic extends Component<IProps, IState> {

    public state = {
        showInitials: false,
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        const { imageUrl, name } = this.props;
        // on profile pic change reset initials flag to show image
        if ((prevProps.imageUrl !== imageUrl)
            || (prevProps.name !== name)){
            this.setState({showInitials: false});
        }
    }

    public render() {
        const { className, placeholder, initialsClassName, retry = { count: 0 }, name, alt } = this.props;
        return (
            !this.state.showInitials ?
                <Avatar icon={<Img
                    alt={alt}
                    src={this.props.imageUrl || name }
                    onError={this.imageUrlError}
                    retry={retry}
                    className={`wf_profile_pic ${className}`}
                    placeholder={placeholder}
                />}
                    size={this.props.size}
                    shape={this.props.shape || 'circle'}
                    className={initialsClassName}
                />
                :
                <Avatar
                    size={this.props.size}
                    className={initialsClassName}
                    shape={this.props.shape || 'circle'}
                >
                    {DataMappingUtils.getInitials(this.props.name).toUpperCase()}
                </Avatar>
        );
    }

    private imageUrlError = () => {
        this.setState({
            showInitials: true,
        });
    }
}
