import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from 'antd';

import { getLoggedInUserId } from '@utils/UserUtils';

import { NotificationModal } from '@common/domain/models/Notification';

import { ModalClose } from '@assets/images';
import { NotificationBell } from '@assets/index';
import { translate } from "@translations/Translater";

import { WFTextNormal } from '@components/index';
import WFModal from '@components/molecules/Modal';

import { notificationMessages } from '@containers/Notifications/messages';

import NotificationListItem from './components/NotificationItem';
import './styles.less';

interface IProps {
    notificationCount: number;
    getNotifications: (userId: string, page: number, limit: number) => void;
    listOfNotification:NotificationModal[],
    setAllNotificationSeen: (userId:string) => void,
    totalNotificationCount:number
}
const Notifications = (props: IProps) => {
    const { notificationCount, getNotifications, totalNotificationCount, setAllNotificationSeen, listOfNotification } = props;
    const { t } = useTranslation();
    const userId = getLoggedInUserId();
    const [openModal, setOpenModal] = useState(false);
    const [page, setPage] = useState(1);
    const PAGE_LIMIT = 10;
    const closeModal = () => {
        setOpenModal(false);
    };
    const showModal = () => {
        setAllNotificationSeen(userId);
        setOpenModal(true);
    };

    useEffect(() => {
        getNotifications(userId, page, PAGE_LIMIT);
    }, [page]);

    return (
        <>
            <Badge count={notificationCount}>
                <img onClick={showModal} src={NotificationBell} className="notification_icon"/>
            </Badge>
            <WFModal
                closable={false}
                destroyOnClose
                onCancel={closeModal}
                showModal={openModal}
                wrapClassName="notification__modal"
            >
                <div className="notification__container">
                    <div className="notification__header_container">
                        <WFTextNormal
                            className="notification__title"
                        >{translate(t, notificationMessages.notificationHeader)}
                        </WFTextNormal>
                        <img className="notification__title__cross" onClick={closeModal} src={ModalClose} />
                    </div>
                    <div className="notification__separator" />
                    <div className="notification__scroll">
                        {listOfNotification && listOfNotification.length === 0 && (
                            <WFTextNormal
                                className="notification__empty_data"
                            >{translate(t,notificationMessages.noData)}
                            </WFTextNormal>
                        )}
                        {listOfNotification && listOfNotification.length !== 0 && listOfNotification.map((item:NotificationModal) => (
                            <NotificationListItem data={item} key={item?.getId()} />
                        ))}
                    </div>
                </div>
            </WFModal>
        </>
    );
};

export default Notifications;