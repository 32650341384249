import { CourseCompetencyModel } from "@common/domain/models/CourseCompetency";

import { CourseModel, ILMSSession } from "@models/Course";

import { courseActions, ICourseTag, IFacultyCourses } from "./actions";

export interface ICourseState {
  courses: string[];
  byId: {};
  courseCompetencies: CourseCompetencyModel[];
  courseTags: ICourseTag[];
  facultyCourses: IFacultyCourses[],
  enrolledCourses: {};
  courseList: CourseModel[],
  courseActivityPercentageMap: {},
  enrolledCoursesCount: {},
  courseContentTocMap: {},
  lmsSession: ILMSSession;
  aiLessonMappingData: any;
  getCertificateIdData: any;
  connectDiscourse: any;
  isChangedInActivityStatus: boolean,
  fetchCertifiedFacultyCourses: CourseModel[];
  facultyCertifiedCoursesCount: number;
  courseByTagMap: {},
  openCourseList: CourseModel[];
  openCourseListCount: {};
  enrollToCourse: any;
  enrolledCourseList: any;
  enrolledCourseKeysMap: {},
  microCertificateIdMap: {},
  forumDetailsByCourseIdMap: {},
  discourseSyncToGroup: any,
  guestCourseList: CourseModel[];
  guestCourseDetail: CourseModel;
  activeModuleId: number;
  loaders: {
    fetchCourseDetails: boolean;
    fetchCourseCompetencies: boolean;
    fetchCourseTags: boolean;
    facultyCourses: boolean;
    enrolledCourses: {};
    courseActivityPercentage: boolean;
    courseContentToc: {};
    lmsSession: boolean;
    activityStatus: boolean;
    getCertificateIdData: boolean;
    connectDiscourse: boolean;
    updatingCourseToc: {};
    aiLessonMappingData: boolean;
    fetchCertifiedFacultyCourses: boolean;
    fetchLatestCoursesByTags: boolean;
    openCourseList: boolean;
    enrollToCourse: boolean;
    enrolledCourseKeys: {};
    microCertificateIdMap: {};
    forumDetailsByCourseIdMap: {},
    discourseSyncToGroup: boolean,
    enrolledCourseList:boolean;
    guestCourseList: boolean;
    guestCourseDetail: boolean;
  };
  errors: {
    fetchCourseDetails: string;
    fetchCourseCompetencies: string;
    fetchCourseTags: string;
    facultyCourses: string;
    enrolledCourses: {};
    courseActivityPercentage: string;
    courseContentToc: {};
    lmsSession: string;
    activityStatus: string;
    aiLessonMappingData: string;
    getCertificateIdData: string;
    connectDiscourse: string;
    fetchCertifiedFacultyCourses: string;
    fetchLatestCoursesByTags: string;
    openCourseList: string;
    enrollToCourse: string;
    enrolledCourseKeys: string;
    microCertificateIdMap: {};
    forumDetailsByCourseIdMap: {},
    discourseSyncToGroup: string
    enrolledCourseList:string;
    guestCourseList: string;
    guestCourseDetail: string;
  };
}

export const initialState = {
  courses: [],
  byId: {},
  courseCompetencies: [],
  courseTags: [],
  facultyCourses: [],
  enrolledCourses: {},
  courseList: [],
  courseActivityPercentageMap: {},
  enrolledCoursesCount: {},
  courseContentTocMap: {},
  lmsSession: {} as ILMSSession,
  getCertificateIdData: {},
  connectDiscourse: {},
  aiLessonMappingData: {},
  isChangedInActivityStatus: false,
  fetchCertifiedFacultyCourses: [],
  facultyCertifiedCoursesCount: 0,
  courseByTagMap: {},
  openCourseList: [],
  openCourseListCount: {},
  enrollToCourse: {},
  enrolledCourseList: [],
  microCertificateIdMap: {},
  forumDetailsByCourseIdMap: {},
  discourseSyncToGroup: {},
  enrolledCourseKeysMap: {},
  guestCourseList: [],
  guestCourseDetail: null,
  activeModuleId: null,
  loaders: {
    fetchCourseDetails: false,
    fetchCourseCompetencies: false,
    fetchCourseTags: false,
    facultyCourses: false,
    enrolledCourses: {},
    courseActivityPercentage: false,
    courseContentToc: {},
    lmsSession: false,
    activityStatus: false,
    getCertificateIdData: false,
    connectDiscourse: false,
    fetchCertifiedFacultyCourses: false,
    fetchLatestCoursesByTags: false,
    openCourseList: false,
    enrollToCourse: false,
    updatingCourseToc: {},
    aiLessonMappingData: false,
    enrolledCourseList: false,
    forumDetailsByCourseIdMap: {},
    discourseSyncToGroup: false,
    enrolledCourseKeys: {},
    microCertificateIdMap: {},
    guestCourseList: false,
    guestCourseDetail: false
  },
  errors: {
    fetchCourseDetails: "",
    fetchCourseCompetencies: "",
    fetchCourseTags: "",
    facultyCourses: "",
    enrolledCourses: {},
    courseActivityPercentage: "",
    courseContentToc: {},
    lmsSession: "",
    activityStatus: "",
    getCertificateIdData: "",
    connectDiscourse: "",
    fetchLatestCoursesByTags: "",
    openCourseList: "",
    enrollToCourse: "",
    aiLessonMappingData: "",
    fetchCertifiedFacultyCourses: "",
    enrolledCourseList: "",
    microCertificateIdMap: {},
    forumDetailsByCourseIdMap: {},
    discourseSyncToGroup: "",
    enrolledCourseKeys: "",
    guestCourseList: "",
    guestCourseDetail: ""
  },
};

export const courseReducer = (
  state: ICourseState = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case courseActions.FETCH_MULTIPLE_COURSES_DETAILS:
      return {
        ...state,
        loaders: { ...state.loaders, fetchCourseDetails: true },
        errors: { ...state.errors, fetchCourseDetails: "" },
      };
    case courseActions.FETCH_MULTIPLE_COURSES_DETAILS_SUCCESS:
      const courses: CourseModel[] = action.payload.courseData;
      updateCourseMap(courses, state.byId);
      const courseIds = courses.map((course) => course.getCourseId());

      return {
        ...state,
        courseList: [...state.courseList, ...action.payload.courseData],
        courses: courseIds,
        loaders: { ...state.loaders, fetchCourseDetails: false },
      };
    case courseActions.FETCH_MULTIPLE_COURSES_DETAILS_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchCourseDetails: false },
        errors: { ...state.errors, fetchCourseDetails: action.payload },
      };
    case courseActions.FETCH_COURSE_COMPETENCIES:
      return {
        ...state,
        loaders: { ...state.loaders, fetchCourseCompetencies: true },
        errors: { ...state.errors, fetchCourseCompetencies: "" }
      };
    case courseActions.FETCH_COURSE_COMPETENCIES_SUCCESS:
      return {
        ...state,
        courseCompetencies: action.payload.courseCompetencies,
        loaders: { ...state.loaders, fetchCourseCompetencies: false },
      };
    case courseActions.FETCH_COURSE_COMPETENCIES_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchCourseCompetencies: false },
        errors: { ...state.errors, fetchCourseCompetencies: action.payload }
      };
    // courseTags
    case courseActions.FETCH_COURSE_TAGS:
      return {
        ...state,
        loaders: { ...state.loaders, fetchCourseTags: true },
        errors: { ...state.errors, fetchCourseTags: "" }
      };
    case courseActions.FETCH_COURSE_TAGS_SUCCESS:
      return {
        ...state,
        courseTags: action.payload.courseTags,
        loaders: { ...state.loaders, fetchCourseTags: false },
      };
    case courseActions.FETCH_COURSE_TAGS_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchCourseTags: false },
        errors: { ...state.errors, fetchCourseTags: action.payload }
      };

    // facultyCourses
    case courseActions.FETCH_FACULTY_COURSES:
      return {
        ...state,
        loaders: { ...state.loaders, facultyCourses: true },
        errors: { ...state.errors, facultyCourses: "" }
      };
    case courseActions.FETCH_FACULTY_COURSES_SUCCESS:
      return {
        ...state,
        facultyCourses: action.payload,
        loaders: { ...state.loaders, facultyCourses: false },
      };
    case courseActions.FETCH_FACULTY_COURSES_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, facultyCourses: false },
        errors: { ...state.errors, facultyCourses: action.payload }
      };
    case courseActions.FETCH_ENROLLED_COURSES:
      const status = action.payload.status;
      if (status) {
        return {
          ...state,
          loaders: {
            ...state.loaders,
            enrolledCourses: {
              ...state.loaders.enrolledCourses,
              [status]: true,
            }
          },
          errors: {
            ...state.errors,
            enrolledCourses: {
              ...state.errors.enrolledCourses,
              [status]: "",
            }
          }
        }
      } else {
        return {
          ...state,
          loaders: { ...state.loaders, enrolledCourseList: true },
          errors: { ...state.errors, enrolledCourseList: "" }
        }
      }
    case courseActions.FETCH_ENROLLED_COURSES_SUCCESS:
      const courseStatus = action.payload.status;
      if (courseStatus) {
        return {
          ...state,
          enrolledCourses: {
            ...state.enrolledCourses,
            [courseStatus]: action.payload.response,
          },
          enrolledCoursesCount: {
            ...state.enrolledCoursesCount,
            [courseStatus]: action.payload.total,
          },
          loaders: {
            ...state.loaders,
            enrolledCourses: {
              ...state.loaders.enrolledCourses,
              [courseStatus]: false,
            }
          },
          errors: {
            ...state.errors,
            enrolledCourses: {
              ...state.errors.enrolledCourses,
              [courseStatus]: "",
            }
          }
        }
      } else {
        return {
          ...state,
          enrolledCourseList: action.payload.response,
          loaders: { ...state.loaders, enrolledCourseList: false },
        }
      }
    case courseActions.FETCH_ENROLLED_COURSES_FAIL:
      if (action.payload.status) {
        return {
          ...state,
          loaders: {
            ...state.loaders,
            enrolledCourses: {
              ...state.loaders.enrolledCourses,
              [action.payload.status]: false,
            }
          },
          errors: {
            ...state.errors,
            enrolledCourses: {
              ...state.errors.enrolledCourses,
              [action.payload.status]: action.payload?.error,
            }
          }
        }
      } else {
        return {
          ...state,
          loaders: { ...state.loaders, enrolledCourseList: false },
          errors: { ...state.errors, enrolledCourseList: action.payload?.error }
        }
      }
    // course activity percentage
    case courseActions.FETCH_COURSE_ACTIVITY_PERCENTAGE:
      return {
        ...state,
        loaders: { ...state.loaders, courseActivityPercentage: true },
        errors: { ...state.errors, courseActivityPercentage: "" }
      }
    case courseActions.FETCH_COURSE_ACTIVITY_PERCENTAGE_SUCCESS:
      updateCourseActivityPercentageMap(action.payload, state.courseActivityPercentageMap);
      return {
        ...state,
        courseActivityPercentage: action.payload,
        loaders: { ...state.loaders, courseActivityPercentage: false },
        errors: { ...state.errors, courseActivityPercentage: "" }
      }
    case courseActions.FETCH_COURSE_ACTIVITY_PERCENTAGE_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, courseActivityPercentage: false },
        errors: { ...state.errors, courseActivityPercentage: action.payload }
      }
    // Course Toc
    case courseActions.FETCH_COURSE_CONTENT:
      return {
        ...state,
        isChangedInActivityStatus: false,
        loaders: {
          ...state.loaders,
          courseContentToc: {
            ...state.loaders.courseContentToc,
            [action.payload.courseId]: true
          }
        },
        errors: {
          ...state.errors,
          courseContentToc: {
            ...state.errors.courseContentToc,
            [action.payload.courseId]: ""
          }
        }
      }
    case courseActions.FETCH_COURSE_CONTENT_SUCCESS:
      const courseId = action?.payload?.contents?.courseId;

      return {
        ...state,
        courseContentTocMap: {
          ...state.courseContentTocMap,
          [courseId]: action.payload
        },
        loaders: {
          ...state.loaders,
          courseContentToc: {
            ...state.loaders.courseContentToc,
            [courseId]: false
          },
          updatingCourseToc: {
            ...state.loaders.updatingCourseToc,
            [courseId]: false
          }
        },
        errors: {
          ...state.errors,
          courseContentToc: {
            ...state.errors.courseContentToc,
            [courseId]: ""
          }
        }
      }
    case courseActions.FETCH_COURSE_CONTENT_FAIL:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          courseContentToc: {
            ...state.loaders.courseContentToc,
            [action.payload.courseId]: false
          },
          updatingCourseToc: {
            ...state.loaders.updatingCourseToc,
            [action.payload.courseId]: false
          }
        },
        errors: {
          ...state.errors,
          courseContentToc: {
            ...state.errors.courseContentToc,
            [action.payload.courseId]: action.payload.error
          }
        }
      }
    case courseActions.FETCH_UPDATED_COURSE_TOC:
      return {
        ...state,
        isChangedInActivityStatus: false,
        loaders: {
          ...state.loaders,
          updatingCourseToc: {
            ...state.loaders.updatingCourseToc,
            [action.payload.courseId]: false
          }
        },
      }
    // lms Sessoin
    case courseActions.FETCH_LMS_SESSION:
      return {
        ...state,
        loaders: { ...state.loaders, lmsSession: true },
        errors: { ...state.errors, lmsSession: '' }
      }
    case courseActions.FETCH_LMS_SESSION_SUCCESS:
      return {
        ...state,
        lmsSession: action.payload,
        loaders: { ...state.loaders, lmsSession: false },
      }
    case courseActions.FETCH_LMS_SESSION_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, lmsSession: false },
        errors: { ...state.errors, lmsSession: action.payload }
      }
    case courseActions.FETCH_ACTIVITY_STATUS:
      return {
        ...state,
        // isChangedInActivityStatus: false,
        loaders: { ...state.loaders, activityStatus: true },
        errors: { ...state.errors, activityStatus: '' }
      }
    case courseActions.FETCH_ACTIVITY_STATUS_SUCCESS:
      const activityCourseId = action?.payload?.courseId;
      const { tocContent, isChangedInStatus } = getUpdatedCourseContentToc(state.courseContentTocMap[activityCourseId], action.payload, state.isChangedInActivityStatus);
      return {
        ...state,
        courseContentTocMap: {
          ...state.courseContentTocMap,
          [activityCourseId]: tocContent
        },
        isChangedInActivityStatus: isChangedInStatus,
        loaders: { ...state.loaders, activityStatus: false },
      }
    case courseActions.FETCH_ACTIVITY_STATUS_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, activityStatus: false },
        errors: { ...state.errors, activityStatus: action.payload }

      }
    case courseActions.GET_CERTIFICATE_ID:
      return {
        ...state,
        loaders: { ...state.loaders, getCertificateIdData: true },
        errors: { ...state.errors, getCertificateIdData: '' }
      }
    case courseActions.GET_CERTIFICATE_ID_SUCCESS:
      return {
        ...state,
        getCertificateIdData: action.payload,
        loaders: { ...state.loaders, getCertificateIdData: false },
      }
    case courseActions.GET_CERTIFICATE_ID_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, getCertificateIdData: false },
        errors: { ...state.errors, getCertificateIdData: action.payload }
      }
    case courseActions.CONNECT_DISCOURSE:
      return {
        ...state,
        loaders: { ...state.loaders, connectDiscourse: true },
        errors: { ...state.errors, connectDiscourse: '' }
      }
    case courseActions.CONNECT_DISCOURSE_SUCCESS:
      return {
        ...state,
        connectDiscourse: action.payload,
        loaders: { ...state.loaders, connectDiscourse: false },
      }
    case courseActions.CONNECT_DISCOURSE_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, connectDiscourse: false },
        errors: { ...state.errors, connectDiscourse: action.payload?.details?.message }
      }
    case courseActions.FETCH_AI_LESSON_MAPPING:
      return {
        ...state,
        loaders: { ...state.loaders, aiLessonMappingData: true },
        errors: { ...state.errors, aiLessonMappingData: '' }
      }
    case courseActions.FETCH_AI_LESSON_MAPPING_SUCCESS:
      return {
        ...state,
        aiLessonMappingData: action.payload,
        loaders: { ...state.loaders, aiLessonMappingData: false },
      }
    case courseActions.FETCH_AI_LESSON_MAPPING_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, aiLessonMappingData: false },
        errors: { ...state.errors, aiLessonMappingData: action.payload }
      }
    // faculty courses
    case courseActions.FETCH_CERTIFIED_FACULTY_COURSES:
      return {
        ...state,
        loaders: { ...state.loaders, fetchCertifiedFacultyCourses: true },
        errors: { ...state.errors, fetchCertifiedFacultyCourses: "" }
      };
    case courseActions.FETCH_CERTIFIED_FACULTY_COURSES_SUCCESS:
      return {
        ...state,
        fetchCertifiedFacultyCourses: action.payload.pageNumber === 1 ? action.payload.courses : [...state?.fetchCertifiedFacultyCourses, ...action?.payload?.courses],
        facultyCertifiedCoursesCount: action.payload.total,
        loaders: { ...state.loaders, fetchCertifiedFacultyCourses: false },
        errors: { ...state.errors, fetchCertifiedFacultyCourses: "" }
      }
    case courseActions.FETCH_CERTIFIED_FACULTY_COURSES_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchCertifiedFacultyCourses: false },
        errors: { ...state.errors, fetchCertifiedFacultyCourses: action.payload }
      };

    // latest courses
    case courseActions.FETCH_LATEST_COURSES_BY_TAGS:
      return {
        ...state,
        loaders: { ...state.loaders, fetchLatestCoursesByTags: true },
        errors: { ...state.errors, fetchLatestCoursesByTags: "" }
      };
    case courseActions.FETCH_LATEST_COURSES_BY_TAGS_SUCCESS:
      const key = Object.keys(action.payload.tag)[0];
      return {
        ...state,
        courseByTagMap: {
          ...state.courseByTagMap,
          [key]: {
            ...state.courseByTagMap[key],
            [action.payload.tag[key]]: {
              courses: action.payload.courses,
              total: action.payload.total
            }
          }
        },
        loaders: { ...state.loaders, fetchLatestCoursesByTags: false },
        errors: { ...state.errors, fetchLatestCoursesByTags: "" }
      }
    case courseActions.FETCH_LATEST_COURSES_BY_TAGS_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchLatestCoursesByTags: false },
        errors: { ...state.errors, fetchLatestCoursesByTags: action.payload }
      };
    case courseActions.FETCH_OPEN_COURSES_LIST:
      return {
        ...state,
        loaders: { ...state.loaders, openCourseList: true },
        errors: { ...state.errors, openCourseList: "" }
      };
    case courseActions.FETCH_OPEN_COURSES_LIST_SUCCESS:
      const openCourses: CourseModel[] = action.payload.courseList;
      updateCourseMap(openCourses, state.byId);
      return {
        ...state,
        openCourseList: {
          ...state.openCourseList,
          [action.payload.instituteId]: {
            ...state.openCourseList[action.payload.instituteId],
            [action.payload.languageCode]: openCourses
          }
        },
        openCourseListCount: {
          ...state.openCourseListCount,
          [action.payload.instituteId]: {
            ...state.openCourseListCount[action.payload.instituteId],
            [action.payload.languageCode]: action.payload?.totalCount,
          }
        },
        loaders: { ...state.loaders, openCourseList: false }
      }
    case courseActions.FETCH_OPEN_COURSES_LIST_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, openCourseList: false },
        errors: { ...state.errors, openCourseList: action.payload }
      }
    case courseActions.ENROLL_TO_COURSE:
      return {
        ...state,
        loaders: { ...state.loaders, enrollToCourse: true },
        errors: { ...state.errors, enrollToCourse: "" }
      };
    case courseActions.ENROLL_TO_COURSE_SUCCESS:
      updateEnrollStatus(state?.byId, action.payload.courseId)
      return {
        ...state,
        enrollToCourse: action.payload?.responseData,
        loaders: { ...state.loaders, enrollToCourse: false }
      }
    case courseActions.ENROLL_TO_COURSE_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, enrollToCourse: false },
        errors: { ...state.errors, enrollToCourse: action.payload }
      }
    // enrolled course keys
    case courseActions.FETCH_ENROLLED_COURSE_KEYS:
      return {
        ...state,
        loaders: {
          ...state.loaders, enrolledCourseKeys: {
            ...state.loaders.enrolledCourseKeys,
            [action.payload.status]: true
          }
        },
        errors: { ...state.errors, enrolledCourseKeys: '' }
      }
    case courseActions.FETCH_ENROLLED_COURSE_KEYS_SUCCESS:
      return {
        ...state,
        enrolledCourseKeysMap: {
          ...state.enrolledCourseKeysMap,
          [action.payload.status]: action.payload.response
        },
        loaders: {
          ...state.loaders, enrolledCourseKeys: {
            ...state.loaders.enrolledCourseKeys,
            [action.payload.status]: false
          }
        },
        errors: { ...state.errors, enrolledCourseKeys: '' }
      }
    case courseActions.FETCH_ENROLLED_COURSE_KEYS_FAIL:
      return {
        ...state,
        loaders: {
          ...state.loaders, enrolledCourseKeys: {
            ...state.loaders.enrolledCourseKeys,
            [action.payload.status]: false
          }
        },
        errors: { ...state.errors, enrolledCourseKeys: action.payload }
      }

    case courseActions.GET_MICRO_CERTIFICATE_ID:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          microCertificateIdMap: {
            ...state.loaders.microCertificateIdMap,
            [action.payload.instanceId]: true
          }
        },
        errors: {
          ...state.errors,
          microCertificateIdMap: {
            ...state.errors.microCertificateIdMap,
            [action.payload.instanceId]: ""
          }
        }
      }
    case courseActions.GET_MICRO_CERTIFICATE_ID_SUCCESS:
      // const mapData =  updateMicroCertificateIdMap(state.microCertificateIdMap, action.payload.instanceId,action.payload.responseData);
      return {
        ...state,
        microCertificateIdMap: { ...state.microCertificateIdMap, [action?.payload?.instanceId]: action.payload.responseData?.certificates[0]?.id },
        loaders: {
          ...state.loaders,
          microCertificateIdMap: {
            ...state.loaders.microCertificateIdMap,
            [action.payload.instanceId]: false
          }
        }
      }
    case courseActions.GET_MICRO_CERTIFICATE_ID_FAIL:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          microCertificateIdMap: {
            ...state.loaders.microCertificateIdMap,
            [action.payload.instanceId]: false
          }
        },
        errors: {
          ...state.errors,
          microCertificateIdMap: {
            ...state.errors.microCertificateIdMap,
            [action.payload.instanceId]: action.payload.instanceId
          }
        }
      }
    case courseActions.GET_FORUM_DETAILS_BYCOURSEID:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          forumDetailsByCourseIdMap: {
            ...state.loaders.forumDetailsByCourseIdMap,
            [action.payload.courseId]: true
          }
        },
        errors: {
          ...state.errors,
          forumDetailsByCourseIdMap: {
            ...state.errors.forumDetailsByCourseIdMap,
            [action.payload.courseId]: ""
          }
        }
      }
    case courseActions.GET_FORUM_DETAILS_BYCOURSEID_SUCCESS:
      return {
        ...state,
        forumDetailsByCourseIdMap: { ...state.forumDetailsByCourseIdMap, [action?.payload?.courseId]: action.payload.responseData },
        loaders: {
          ...state.loaders,
          forumDetailsByCourseIdMap: {
            ...state.loaders.forumDetailsByCourseIdMap,
            [action.payload.courseId]: false
          }
        }
      }
    case courseActions.GET_FORUM_DETAILS_BYCOURSEID_FAIL:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          forumDetailsByCourseIdMap: {
            ...state.loaders.forumDetailsByCourseIdMap,
            [action.payload.courseId]: false
          }
        },
        errors: {
          ...state.errors,
          forumDetailsByCourseIdMap: {
            ...state.errors.forumDetailsByCourseIdMap,
            [action.payload.courseId]: action.payload.instanceId
          }
        }
      }
    case courseActions.DISCOURSE_SYNC_TO_GROUP:
      return {
        ...state,
        loaders: { ...state.loaders, discourseSyncToGroup: true },
        errors: { ...state.errors, discourseSyncToGroup: '' }
      }
    case courseActions.DISCOURSE_SYNC_TO_GROUP_SUCCESS:
      return {
        ...state,
        discourseSyncToGroup: action.payload,
        loaders: { ...state.loaders, discourseSyncToGroup: false },
      }
    case courseActions.DISCOURSE_SYNC_TO_GROUP_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, discourseSyncToGroup: false },
        errors: { ...state.errors, discourseSyncToGroup: action.payload }
      }

    case courseActions.GUEST_COURSES_LIST:
      return {
        ...state,
        loaders: { ...state.loaders, guestCourseList: true },
        errors: { ...state.errors, guestCourseList: "" }
      }
    case courseActions.GUEST_COURSES_LIST_SUCCESS:
      return {
        ...state,
        guestCourseList: action.payload,
        loaders: { ...state.loaders, guestCourseList: false },
        errors: { ...state.errors, guestCourseList: "" }
      }
    case courseActions.GUEST_COURSES_LIST_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, guestCourseList: false },
        errors: { ...state.errors, guestCourseList: action.payload }
      }
    // guest course detail
    case courseActions.GUEST_COURSES_DETAIL:
      return {
        ...state,
        loaders: { ...state.loaders, guestCourseDetail: true },
        errors: { ...state.errors, guestCourseDetail: "" }
      }
    case courseActions.GUEST_COURSES_DETAIL_SUCCESS:
      return {
        ...state,
        guestCourseDetail: action.payload,
        loaders: { ...state.loaders, guestCourseDetail: false },
        errors: { ...state.errors, guestCourseDetail: "" }
      }
    case courseActions.GUEST_COURSES_DETAIL_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, guestCourseDetail: false },
        errors: { ...state.errors, guestCourseDetail: action.payload }
      }
    case courseActions.SET_ACTIVE_MODULE:
        return {
          ...state,
        }
    case courseActions.SET_ACTIVE_MODULE_SUCCESS:
        return {
          ...state,
          activeModuleId: action.payload,
        }
    default:
      return state;
  }
};

export const updateCourseActivityPercentageMap = (courses, existingMap) => {
  courses.forEach((course) => {
    existingMap[course.courseId] = course
  });
}

export const updateCourseMap = (
  newCourses: any[] | CourseModel[],
  existingMap: any
) => {
  newCourses.forEach((course) => {
    try {
      if (course instanceof CourseModel) existingMap[course.getCourseId()] = course;
      //@ts-ignore
      else existingMap[course.courseId] = course;
    } catch (error) { }
  });
};

// This func is for old moodle version 3.4
// export const getUpdatedCourseContentToc = (tocContent, payload, isChangedInActivityStatus) => {
//   let isChangedInStatus = isChangedInActivityStatus;
//   const getSection = tocContent?.contents?.sections?.find(section => section.id === payload.sectionId);
//   const getLesson = getSection?.subsections?.find(sub => sub.id === payload.lessonId);
//   const getActivityIndex = getLesson?.mods?.findIndex(mod => mod.instance === payload?.activity?.instance);
//   const activityList = getLesson?.mods;
//   if (activityList[getActivityIndex]?.userStatus !== payload?.response?.status) {
//     isChangedInStatus = true;
//   }
//   activityList[getActivityIndex] = { ...payload.activity, userStatus: payload?.response?.status, userEndTime: payload?.response?.endTime, instance: payload?.activity?.instance };
//   getLesson.mods = activityList;
//   return { tocContent, isChangedInStatus };
// }

export const getUpdatedCourseContentToc = (tocContent, payload, isChangedInActivityStatus) => {
  let isChangedInStatus = isChangedInActivityStatus;
  const getSection = tocContent?.contents?.modules?.find(section => section.id === payload.sectionId);
  const getLesson = tocContent?.contents?.modules?.find(sub => sub.id === payload.lessonId);
  const getActivityIndex = getLesson?.modules?.findIndex(mod => mod.instance === payload?.activity?.instance);
  const activityList = getLesson?.modules;
  const getlastActive = tocContent?.lastVisited;
  if (activityList[getActivityIndex]?.userStatus !== payload?.response?.status) {
    isChangedInStatus = true;
  }
  tocContent.lastVisited = {...getlastActive, lastVisitedActivityId:  payload?.activity?.instance}
  activityList[getActivityIndex] = { ...payload.activity, userStatus: payload?.response?.status, userEndTime: payload?.response?.endTime, instance: payload?.activity?.instance };
  getLesson.modules = activityList;
  return { tocContent, isChangedInStatus };
}
export const updateEnrollStatus = (existingMap: {}, courseId: string) => {
  let existingCourseDetails = existingMap[courseId];
  existingCourseDetails.userStatus = "";
  existingMap = {
    ...existingMap,
    [courseId]: existingCourseDetails
  }
}

export const updateMicroCertificateIdMap = (existingMap: {}, instanceId: string, data: any) => {
  const updatedMap = {
    ...existingMap,
    [instanceId]: data?.certificates && data?.certificates[0]?.id
  }
  return updatedMap;
}
