import { Cookies } from "react-cookie";
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from "redux-saga/effects";

import { getErrorCodes } from "@utils/ErrorMessageUtils";
import { getLocalisationData, updateLocalisationData } from "@utils/LanguageUtils";
import { LocalStorage, StorageKeys } from "@utils/LocalStorage";
import { Logger } from "@utils/Logger";
import { storeSocialMediaUserInfo, storeUserData } from '@utils/UserUtils';

import { AuthRepository } from "@repositories/AuthRepository";

import { LoginModel } from "@common/domain/models/Login";
import { resetNetworkLayerCache } from "@common/network/clientCacheHandler";

import {
    authActions,
    changePasswordFail,
    changePasswordSuccess,
    checkUserExistFail,
    checkUserExistSuccess,
    emailVerificationFail,
    emailVerificationSuccess,
    forgotPasswordFail,
    forgotPasswordSuccess,
    getNsdcSsoUrlFail,
    getNsdcSsoUrlSuccess,
    loginFail,
    loginSuccess,
    logoutFailureAction,
    logoutSuccessAction,
    registerFail,
    registerSuccess,
    resendVerifyMailFail,
    resendVerifyMailSuccess,
    resetPasswordFail,
    resetPasswordSuccess,
    setAuthenticatedFlag
} from "@redux/auth/actions";
import { userPersona } from '@redux/auth/selectors';
import { userRoles } from '@redux/constants';

import { IFluxStandardAction } from "@store/webInterfaces";
import {
    ICheckUserExist,
    IEmailVerify,
    IForgotPassword,
    ILoginPayload,
    ILogoutAction,
    ILogoutModel,
    IResetPassword,
    ISocialMediaLogin,
    ISSOLoginPayload
} from "./interface";

const cookies = new Cookies();

export function* loginSaga(action: IFluxStandardAction<ILoginPayload>): Generator<StrictEffect, void, LoginModel> {
    try {
        let loginResponse: any;
        const { userName } = action.payload;
        if(!action.payload.fromSuperAdminSignIn) {
            loginResponse = yield call(AuthRepository.login, action.payload);
        } else loginResponse = action.payload.userData;

            const { isProfileCompleted, tempPasswordGenerated } = loginResponse.preferences;
            storeUserData(loginResponse);
            const userRole = ((loginResponse.role && loginResponse.role[0]) || loginResponse.role) as string;
            if ((isProfileCompleted === 'true' || userRole) && (tempPasswordGenerated === 'false' || !tempPasswordGenerated)) {
                yield put(setAuthenticatedFlag(true));
            }
            yield put(loginSuccess(loginResponse));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(setAuthenticatedFlag(false));
        yield put(loginFail(error));
    }
}

export function* ssoLoginSaga(action: IFluxStandardAction<ISSOLoginPayload>): Generator<StrictEffect, void, LoginModel> {
    try {
        const loginResponse: LoginModel = yield call(AuthRepository.ssoLogin, action.payload);
        const { isProfileCompleted, tempPasswordGenerated } = loginResponse.preferences;
        storeUserData(loginResponse);
        const userRole = ((loginResponse.role && loginResponse.role[0]) || loginResponse.role) as string;
        if((isProfileCompleted === 'true' || userRole) && (tempPasswordGenerated === 'false' || !tempPasswordGenerated)) {
            yield put(setAuthenticatedFlag(true));
        } 
        yield put(loginSuccess(loginResponse));
    } catch (e) {
        yield put(setAuthenticatedFlag(false));
        yield put(loginFail(e.message));
    }
}

export function* socialMediaLoginSaga(action: IFluxStandardAction<ISocialMediaLogin>): Generator<StrictEffect, void, LoginModel> {
    try {
        const loginResponse: LoginModel = yield call(AuthRepository.socialMediaLogin, action.payload);
        const { isProfileCompleted, tempPasswordGenerated } = loginResponse.preferences;
        storeUserData(loginResponse);
        if (isProfileCompleted === 'false') storeSocialMediaUserInfo({ ...action.payload.userInfo, type: action.payload.type });
        const userRole = ((loginResponse.role && loginResponse.role[0]) || loginResponse.role) as string;
        if((isProfileCompleted === 'true' || userRole) && (tempPasswordGenerated === 'false' || !tempPasswordGenerated)) {
            yield put(setAuthenticatedFlag(true));
        } 
        yield put(loginSuccess(loginResponse));
    } catch (e) {
        yield put(setAuthenticatedFlag(false));
        yield put(loginFail(e.message));
    }
}

export function* checkUserExistSaga(action: IFluxStandardAction<ICheckUserExist>): Generator<StrictEffect, void, any> {
    try {
        const checkUserExistResponse: any = yield call(AuthRepository.checkUserExist, action.payload);
        yield put(checkUserExistSuccess(checkUserExistResponse));
    } catch (e) {
        yield put(checkUserExistFail(e.message));
    }
}

export function* registerSaga(action: IFluxStandardAction<ILoginPayload>): Generator<StrictEffect, void, any> {
    try {
        const registerResponse: any = yield call(AuthRepository.register, action.payload);
        yield put(registerSuccess(registerResponse));
    } catch (e) {
        yield put(setAuthenticatedFlag(false));
        const error = getErrorCodes(e);
        yield put(registerFail(error));
    }
}

export function* resendVerifyMailSaga(action: IFluxStandardAction<ILoginPayload>): Generator<StrictEffect, void, any> {
    try {
        const resendVerifyMailResponse: any = yield call(AuthRepository.resendVerifyMail, action.payload);
        yield put(resendVerifyMailSuccess(resendVerifyMailResponse));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(resendVerifyMailFail(error));
    }
}

export function* forgotPasswordSaga(action: IFluxStandardAction<IForgotPassword>): Generator<StrictEffect, void, {}> {
    try {
        const forgotPasswordResponse = yield call(AuthRepository.forgotPassword, action.payload);
        yield put(forgotPasswordSuccess(forgotPasswordResponse));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(forgotPasswordFail(error));
    }
}

export function* changePassword(action: IFluxStandardAction<ILoginPayload>): Generator<StrictEffect, void, any> {
    try {
        yield call(AuthRepository.changePassword, action.payload);
        const persona = yield select(userPersona);
        if(persona === userRoles.faculty || persona === userRoles.regionalManager || persona === userRoles.instituteAdmin) {
            yield put(setAuthenticatedFlag(true));
        }
        yield put(changePasswordSuccess());
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(changePasswordFail(error));
    }
}

export async function removeUserData() {
    const localisationData = await getLocalisationData();
    let guestLangId: any = await LocalStorage.get(
        StorageKeys.GUEST_USER_LANG,
    );
    // if not found set default lang
    if(!guestLangId) {
      guestLangId = { id: 1, code: 'en' };
    }
    resetNetworkLayerCache();
    await LocalStorage.clearAll();
    await LocalStorage.set(StorageKeys.GUEST_USER_LANG, guestLangId);
    await updateLocalisationData(localisationData);
  }

export function* logoutJob(logoutAction: ILogoutAction): any {
    try {
      if(logoutAction.payload){
        const deviceInfo: ILogoutModel = {device: {installationId: JSON.parse(logoutAction.payload)}} as ILogoutModel;
        yield call(AuthRepository.logout, deviceInfo);
      }
        cookies.set('MoodleSession', '', { path: '/', domain: '.wfglobal.org', maxAge: 0},);
        cookies.set('expires', 0, { path: '/', domain: '.wfglobal.org', maxAge: 0},);
      yield call(AuthRepository.logoutUser);
    //   yield call(AuthRepository.deleteAuthToken);
      yield call(removeUserData);
      yield put(logoutSuccessAction());
    } catch (e) {
      Logger.warn('User logout failed', { error: e });
      yield put(logoutFailureAction({message: e.message}));
    }
  }

export function* resetPasswordSaga(action: IFluxStandardAction<IResetPassword>): Generator<StrictEffect, void, {}> {
    try {
        yield call(AuthRepository.updatePassword, action.payload);
        yield put(resetPasswordSuccess());
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(resetPasswordFail(error));
    }
}

export function* emailVerificationSaga(action: IFluxStandardAction<IEmailVerify>): Generator<StrictEffect, void, {}> {
    try {
        const response  = yield call(AuthRepository.emailVerification, action.payload);
        yield put(emailVerificationSuccess(response));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(emailVerificationFail(error));
    }
}

export function* getNsdcSsoUrlSaga(action) {
    try {
        const response = yield call (AuthRepository.getNsdcSsoUrl,action.payload)
        yield put (getNsdcSsoUrlSuccess(response))
    } catch (error) {
        yield put(getNsdcSsoUrlFail(error))
    }
} 

export function* watchAuth(): Generator<ForkEffect> {
    yield takeLatest(authActions.LOGIN, loginSaga);
    yield takeLatest(authActions.SSO_LOGIN, ssoLoginSaga);
    yield takeLatest(authActions.SOCIAL_MEDIA_LOGIN, socialMediaLoginSaga);
    yield takeLatest(authActions.CHECK_USER_EXIST, checkUserExistSaga);
    yield takeLatest(authActions.REGISTER, registerSaga);
    yield takeLatest(authActions.RESEND_VERIFY_MAIL, resendVerifyMailSaga);
    yield takeLatest(authActions.FORGOT_PASSWORD, forgotPasswordSaga);
    yield takeLatest(authActions.CHANGE_PASSWORD, changePassword);
    yield takeLatest(authActions.LOGOUT, logoutJob);
    yield takeLatest(authActions.RESET_PASSWORD, resetPasswordSaga);
    yield takeLatest(authActions.EMAIL_VERIFICATION, emailVerificationSaga);
    yield takeLatest(authActions.GET_NSDC_SSO_URL,getNsdcSsoUrlSaga);
    yield takeLatest(authActions.SOCIAL_MEDIA_LOGIN, socialMediaLoginSaga);
}



