import { MeetingModel } from '@common/domain/models/Meeting';
import { meetingActions } from '@common/redux/meeting/actions';
import { IMeetingState } from '@common/redux/meeting/interface';

export const initialState = {
    createEvent: '',
    fetchEventList: [],
    eventDetail: null,
    deletedEvent: {},
    updateMeeting: '',
    meetingDetailDraft:{},
    loaders: {
        createEvent: false,
        fetchEventList: false,
        eventDetail: false,
        deletedEvent: false,
        updateMeeting: false,
    },
    errors: {
        createEvent: '',
        fetchEventList: '',
        eventDetail: '',
        deletedEvent: '',
        updateMeeting: ''
    }
}

export const meetingReducer = (state: IMeetingState = initialState,
    action:  { type: string; payload: any }) => {
        switch(action.type) {
            case meetingActions.CREATE_EVENT:
                return {
                    ...state,
                    loaders: { ...state.loaders, createEvent: true },
                    errors: { ...state.errors, createEvent: ''},
                }
            case meetingActions.CREATE_EVENT_SUCCESS:
                return {
                    ...state,
                    createEvent: action.payload,
                    loaders: { ...state.loaders, createEvent: false },
                }
            case meetingActions.CREATE_EVENT_FAIL:
                return {
                    ...state,
                    loaders: { ...state.loaders, createEvent: false },
                    errors: { ...state.errors, createEvent: action.payload},
                }

            // event list
            case meetingActions.FETCH_EVENT_LIST:
                return {
                    ...state,
                    loaders: { ...state.loaders, fetchEventList: true },
                    errors: { ...state.errors, fetchEventList: ''},
                }
            case meetingActions.FETCH_EVENT_LIST_SUCCESS:
                return {
                    ...state,
                    fetchEventList: action.payload,
                    loaders: { ...state.loaders, fetchEventList: false },
                }
            case meetingActions.FETCH_EVENT_LIST_FAIL:
                return {
                    ...state,
                    loaders: { ...state.loaders, fetchEventList: false },
                    errors: { ...state.errors, fetchEventList: action.payload},
                }

            // event detail
            case meetingActions.FETCH_EVENT_DETAIL:
                return {
                    ...state,
                    loaders: { ...state.loaders, eventDetail: true },
                    errors: { ...state.errors, eventDetail: ''},
                }
            case meetingActions.FETCH_EVENT_DETAIL_SUCCESS:
                return {
                    ...state,
                    eventDetail: action.payload,
                    loaders: { ...state.loaders, eventDetail: false },
                }
            case meetingActions.FETCH_EVENT_DETAIL_FAIL:
                return {
                    ...state,
                    loaders: { ...state.loaders, eventDetail: false },
                    errors: { ...state.errors, eventDetail: action.payload},
                }

            // delete event
            case meetingActions.DELETE_EVENT:
                return {
                    ...state,
                    loaders: { ...state.loaders, deletedEvent: true },
                    errors: { ...state.errors, deletedEvent: ''},
                }
            case meetingActions.DELETE_EVENT_SUCCESS:
                return {
                    ...state,
                    fetchEventList: state.fetchEventList.filter((event: MeetingModel) => event.getId() !== action.payload),
                    deletedEvent: action.payload,
                    //eventDetail: null,
                    loaders: { ...state.loaders, deletedEvent: false },
                }
            case meetingActions.DELETE_EVENT_FAIL:
                return {
                    ...state,
                    loaders: { ...state.loaders, deletedEvent: false },
                    errors: { ...state.errors, deletedEvent: action.payload},
                }
            case meetingActions.CLEAR_EVENT:
                return {
                    ...state,
                    eventDetail: null,
                    loaders: { ...state.loaders, eventDetail: false },
                    errors: { ...state.errors, eventDetail: "" },
                }
                case meetingActions.UPDATE_MEETING_DETAIL:
                    return {
                        ...state,
                        loaders: { ...state.loaders, updateMeeting: true },
                        errors: { ...state.errors, updateMeeting: '' },
                    }
                case meetingActions.UPDATE_MEETING_DETAIL_SUCCESS:
                    return {
                        ...state,
                        updateMeeting: action.payload,
                        loaders: { ...state.loaders, updateMeeting: false },
                    }
                case meetingActions.UPDATE_MEETING_DETAIL_FAIL:
                    return {
                        ...state,
                        loaders: { ...state.loaders, updateMeeting: false },
                        errors: { ...state.errors, updateMeeting: action.payload },
                    }
            case meetingActions.CREATE_MEETING_DRAFT_DATA:
                return {
                    ...state,
                    meetingDetailDraft: action.payload
                }
            default:
                return state;
        }
    }

