import {deserializeJsonObj, deserializeJsonObjCollection} from "@utils/ObjectMapperUtil";

import { ApiClient } from "@common/network/client";
import { APIBaseServices, clearCacheForService } from "@common/network/clientCacheHandler";
import { APIv1 } from "@common/network/constants";
import { IApiClient } from "@common/network/interfaces";
import { ICreateEvent, IFetchEvent } from "@common/redux/meeting/interface";

import { MeetingModel } from "../models/Meeting";

const ENDPOINTS = {
    createEvent: () => `${APIv1}/organizationservice/events`,
    fetchEventList: (userId: string) => `${APIv1}/organizationservice/events/users/${userId}`,
    event: (id: string) => `${APIv1}/organizationservice/events/${id}`,
}

class MeetingRepository {
    private apiClient: IApiClient;
    constructor(apiClient: IApiClient) {
        this.apiClient = apiClient;
    }

    public createEvent = async (payload: ICreateEvent): Promise<MeetingModel> => {
        clearCacheForService([APIBaseServices.ORGANISATION_SERVICE]);
        const response = await this.apiClient.post(ENDPOINTS.createEvent(), payload);
        return deserializeJsonObj(response, MeetingModel, 'createEvent') as MeetingModel;
    }

    public fetchEventList = async (payload: IFetchEvent): Promise<MeetingModel[]> => {
        const { userId, value, key, startDateTime, endDate, limit } = payload;
        const response = await this.apiClient.get(ENDPOINTS.fetchEventList(userId), { value, key, startDateTime, limit, endDateTime: endDate });
        return deserializeJsonObjCollection(response, MeetingModel, 'fetchEventList') as MeetingModel[];
    }

    public fetchEventDetail = async (payload: string): Promise<MeetingModel> => {
        const response = await this.apiClient.get(ENDPOINTS.event(payload));
        return deserializeJsonObj(response, MeetingModel, 'fetchEventDetail') as MeetingModel;
    }

    public deleteEvent = async (payload: string): Promise<{}> => {
        const response = await this.apiClient.delete(ENDPOINTS.event(payload));
        return response;
    }

    public updateMeetingDetail = async (id: string, payload: ICreateEvent): Promise<MeetingModel> => {
        const response = await this.apiClient.put(ENDPOINTS.event(id), payload);
        return deserializeJsonObj(response, MeetingModel, 'updateMeetingDetail') as MeetingModel;
    }
}

const meetingRepository = new MeetingRepository(ApiClient);

export { meetingRepository as MeetingRepository };

