import { ApiClient } from "@common/network/client";
import { APIv1, APIv2 } from "@common/network/constants";
import { IApiClient } from "@common/network/interfaces";
import {
    deserializeJsonObjCollection,
} from "@utils/ObjectMapperUtil";
import { BatchModel } from "@models/Batch";
import { CourseModel } from "@models/Course";

const ENDPOINTS = {
    fetchInstituteId: () =>
        `${APIv1}/organizationservice/institute/admins`,
    fetchInstituteData: () =>
        `${APIv2}/organizationservice/institutes`,
    fetchCourseIds: () =>
        `${APIv2}/organizationservice/batches?nonPaginated=true`,
    fetchCoursesData: () =>
        `${APIv1}/courseservice/courses?nonPaginated=true&status=published`,
    fetchInstituteDashboardData: (instituteId) =>
        `${APIv1}/organizationservice/bigquery/reports/institute/${instituteId}/batches`,
    downloadInstituteAdminDataReport: (instituteId) =>
        `${APIv1}/organizationservice/bigquery/reports/download/institute/${instituteId}/user/batches`,
    fetchFacultyIds: () =>
        `${APIv1}/organizationservice/institutes/faculties?nonPaginated=true`,
    fetchFacultyData: () =>
        `${APIv1}/profileservice/userprofiles?nonPaginated=true`,
    fetchLastRefreshedData: () =>
        `${APIv1}/organizationservice/bigquery/reports/lastRefreshed`,
};

class InstituteAdminRepository {
    private apiClient: IApiClient;
    constructor(apiClient: IApiClient) {
        this.apiClient = apiClient;
    }

    public fetchInstituteId = async (payload): Promise<String[]> => {
        const response = await this.apiClient.get(
            ENDPOINTS.fetchInstituteId(), { adminIds: payload }
        );
        return response;
    };

    public fetchInstituteData = async (payload): Promise<String[]> => {
        const response = await this.apiClient.get(
            ENDPOINTS.fetchInstituteData(), { instituteId: payload }
        );
        return response;
    };

    public fetchInstituteDashboardData = async (payload): Promise<String[]> => {
        const { instituteId, startDateFrom, startDateTill, status, courseId, facultyId } = payload;
        const response = await this.apiClient.get(
            ENDPOINTS.fetchInstituteDashboardData(instituteId), { courseId, startDateFrom, startDateTill, status, facultyId }
        );
        return response;
    };

    public downloadInstituteAdminDataReport = async (payload): Promise<String[]> => {
        const { instituteId, startDateFrom, startDateTill, status, courseId } = payload;
        const response = await this.apiClient.get(
            ENDPOINTS.downloadInstituteAdminDataReport(instituteId), { courseId, startDateFrom, startDateTill, status }
        );
        return response;
    };

    public fetchCourseIds = async (instituteId: string) => {
        const params = {
            instituteId,
        };
        const response = await this.apiClient.get(
            ENDPOINTS.fetchCourseIds(),
            params,
        );
        const courseDetails = deserializeJsonObjCollection(response.batches, BatchModel, 'fetchCourseIds') as BatchModel[];
        return courseDetails;
    };

    public fetchCoursesData = async (courseIds: string[]) => {
        const response = await this.apiClient.get(ENDPOINTS.fetchCoursesData(), { courseIds });
        const courseDetails = deserializeJsonObjCollection(
            response.courses,
            CourseModel,
            "fetchCoursesData"
        );
        return courseDetails;
    };

    public fetchFacultyIds = async (instituteId: string) => {
        const params = {
            instituteId,
        };
        const response = await this.apiClient.get(
            ENDPOINTS.fetchFacultyIds(),
            params,
        );
        return response?.data;
    };

    public fetchFacultyData = async (userIds: string[]) => {
        const response = await this.apiClient.get(ENDPOINTS.fetchFacultyData(), { userIds });
        return response?.profiles;
    };

    public fetchLastRefreshedData = async () => {
        const response = await this.apiClient.get(ENDPOINTS.fetchLastRefreshedData());
        return response;
    };
}

const instituteAdminRepository = new InstituteAdminRepository(ApiClient);

export { instituteAdminRepository as InstituteAdminRepository };