import { AuthenticationService } from "@services/AuthenticationService";

import { ConversationModel } from "@models/Conversation";

import { i18n } from "@translations/i18n";

import {userRoles} from '@constants/config';
import { AllowPost, ConversationType } from "@constants/config";

import { profileMessages } from "@containers/Profile/messages";

import jwtDecode, { JwtPayload } from "jwt-decode";

const getInitials = (name: string) => {
  if (!name) {
    return '';
  }
  const firstLetters = name
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), '');
  const { length } = firstLetters;
  if (firstLetters && length > 1) {
    return firstLetters.slice(0, 1) + firstLetters.slice(length - 1, length);
  }
  return firstLetters || '';
};



export const getEmbedVideoId = (videoUrl: string): string => {
  const vidReg = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const url = `https://www.youtube.com/embed/${videoUrl.match(vidReg)[1]}`;
  return url;
};

const validateFileSize = (file: any, fileSizeLimit?: number) => {

  const defaultFileSizeLimit = 1024 * 1024 * 2;
  const updatedFileSizeLimit = !fileSizeLimit && defaultFileSizeLimit;

  return file.size <= updatedFileSizeLimit ? true : false;
};


export const getLoggedInUserId = async () => {
  const token = await AuthenticationService.getAccessToken();
  let userId = undefined;
  if (!!token) {
      const userIdSub = jwtDecode<JwtPayload>(token);
      userId = userIdSub.sub;
  }
  return userId;
}

export const getConversationType = (conversation: ConversationModel): string => {
  if (!conversation.getMetadata()){
      return ConversationType.ONE;
  } else if (conversation.getAllowPost() === AllowPost.ALL){
      return ConversationType.GROUP;
  } else if (conversation.getAllowPost() === AllowPost.ADMIN) {
      return ConversationType.ANNOUNCEMENT;
  }
  return ConversationType.ONE;
}

/*
      Utility function for generating unique UUID for each message
  */

 export const generateUuidv4 = () =>
 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
   const r = (Math.random() * 16) | 0;
   const v = c == 'x' ? r : (r & 0x3) | 0x8;
   return v.toString(16);
 });


 export const getYoutubeVideoId = (videoUrl: string): string => {

  if(videoUrl !== '') {
    const vidReg = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const videoId = videoUrl.match(vidReg)[1];
    return videoId;
  }

  return "";

};

const validateYoutubeUrl = (url: string): boolean => {
  const regex = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  if(url.match(regex)){
    return true;
  }
  return false;
} 

const getHttpPrefixedLinkUrl = (url: string) => {
  return url && !url.includes('http') ? `http://${url}` : url;
}

const createUrlLinksInText = (text: string) => {
  const urlRegex = /(https:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => {
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
  });
}

const checkIfSiteHasProtocol = (text: string) => {
  const regex = /^(ftp|http|https):\/\/[^ "]+$/;
  return text.match(regex);
}

export const parseCountryCallingCode = (countryCode: string): number => {
  if (!countryCode) {
    return 0;
  }
  const code = countryCode.replace('+', '');
  // @ts-ignore
  return parseInt(code, 10);
};

const getTranslatedUserRole = (role: userRoles) => {

  switch (role) {
    case userRoles.faculty:
      return i18n.t(profileMessages.facultyRole.key);
    case userRoles.student:
      return i18n.t(profileMessages.studentRole.key);
    case userRoles.regionalManager:
      return i18n.t(profileMessages.rmRole.key);
    default:
      return '';
  }
  
}

export const urlifyText = (text: string) => {
  const urlRegex = /(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?/i ;
  // Reference: https://www.regextester.com/96504
  return text.replace(urlRegex, (url) => {

    let finalURL = url;
    if(!url.includes('http://') && !url.includes('https://') && !url.includes('ftp://'))
      finalURL = `http://${url}`;

    return `<a href="${finalURL}" target="_blank">${url}</a>`;
  });
}

const capitalizeFirstLetter = (str: string) => {
  let sentence = str.toLowerCase().split(" ");
  for(var i = 0; i< sentence.length; i++){
     sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  sentence.join(" ");
  return sentence;
}
const isFacebookvideo = (url:string)=>{
    const regex = /^https?:\/\/www\.facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/gm;
    if (url.match(regex)) {
        return true;
    }
    return false;
};

const getFBVideoId = (videoUrl: string): string => {
  const regex = /(\d+)\/?$/;
  const url = new URL(videoUrl);
  const params = new URLSearchParams(url.search);
  return params.get('story_fbid') || params.get('video_id') || (videoUrl.match(regex) && videoUrl.match(regex)[0]);
};

const removeSpecialChar = (text: string) => {
  return text?.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
}


export const DataMappingUtils = {
  getInitials,
  getEmbedVideoId,
  validateFileSize,
  getLoggedInUserId,
  getConversationType,
  generateUuidv4,
  getHttpPrefixedLinkUrl,
  getYoutubeVideoId,
  validateYoutubeUrl,
  createUrlLinksInText,
  checkIfSiteHasProtocol,
  parseCountryCallingCode,
  getTranslatedUserRole,
  urlifyText,
  capitalizeFirstLetter,
  getFBVideoId,
  isFacebookvideo,
  removeSpecialChar
};


