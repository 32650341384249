import { JsonProperty } from "json-object-mapper";


export interface IMods {
    id: string,
    instance: number,
    modtype: string,
    name: string,
    intro: string,
    tagsname: string,
    visible: number,
    courseCompletionInclude: boolean,
    maxGrade: string, // todo confirm with BE
    userScore: number,
    userScorePercentage: number,
    userStartTime: string,
    userEndTime: string,
    userStatus: string,
    totalScore: string,
    instanceId?:number
}

export interface IModuleActivity {
    id: string
    instance: string
    modtype: string
    name: string
    intro: string
    tagsname: any
    visible: string
    courseCompletionInclude: boolean
    maxGrade: any
    order: number
    userStatus: string
  }


export interface ISubSection {
    id: string,
    name: string,
    description: string,
    visible: number,
    mods: IMods[],
}

export interface ISection {
    description: string,
    id: string,
    name: string,
    visible: number,
    subsections: ISubSection[],
}

export interface IContent {
    courseId: string,
    createdAt: string,
    deleted: boolean,
    sections: ISection[],
    updatedAt: string,
    courseLmsId: number,
    lmsVendor: string,
    _id: string,
    assessmentDetails: any
}

export interface ILastVisited {
    lastVisitedActivityId: string,
    lastVisitedActivityTime: string,
}

export interface IModules {
    id: string
    name: string
    description: string
    visible: number
    order: number
    perent: number | string
    sectionid: string
    levels: number
    modules?: IModuleActivity[]
  }

  export interface IContent {
    courseId: string,
    createdAt: string,
    deleted: boolean,
    sections: ISection[],
    updatedAt: string,
    courseLmsId: number,
    lmsVendor: string,
    _id: string,
    assessmentDetails: any
    modules: IModules[]
}
export class CourseToc {
    @JsonProperty({ name: "contents", required: true })
    private contents: IContent = null;

    @JsonProperty({ name: "lastVisited", required: true })
    private lastVisited: ILastVisited = null;

    public getCourseContents(): IContent {
        return this.contents;
    }

    public getLastVisited(): ILastVisited {
        return this.lastVisited;
    }
}
