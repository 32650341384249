import React, { memo } from 'react';

import { Menu } from 'antd';

import Img from "@components/atoms/Img";
import WFMenuItem from '@components/Menu';

import Navigator from '@navigator/index';

import { IAppMenuItem } from '@layout/Dashboard';
import './style.less';

interface IProps {
    menuItems: IAppMenuItem[];
    className?: string;
    onMenuChange: (key: string, index?: number) => void;
    selectedKey: string;
}

function DashBoardMenu(props: IProps) {
    const { selectedKey, menuItems, onMenuChange } = props;

    // ---- If icon needed uncomment the code ----
    // const renderImage = (iconImage)=>{
    //     switch (iconImage) {
    //       case 'LibraryIcon':
    //       return null
    //       default:
    //         break;
    //     }
    //   };

    return (
        <div className="dashboard_menu_container">
            <Menu
                mode="inline"
                theme="dark"
                defaultSelectedKeys={[menuItems[0].key]}
                selectedKeys={[selectedKey]}
            >
                {menuItems.map((menuItem: IAppMenuItem) => {
                    const onClick = () => {
                        const route = menuItem.url;
                        Navigator.push(route);
                        onMenuChange(menuItem.key);
                    };

                    return (
                        <WFMenuItem key={menuItem.key} onClick={onClick}>
                            <div className="vertical_menu">
                                {/* {menuItem.iconImage && (
                  <Img  alt="menu icon" src={renderImage(menuItem.iconImage)} className="icon_image" />
                )} */}
                                {menuItem.name}
                            </div>
                        </WFMenuItem>
                    );
                })}
            </Menu>
        </div>

    );
}

export default memo(DashBoardMenu);
