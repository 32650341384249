import { AppLanguage, AppLanguageTranslationFile } from '@translations/AppLanguage';
import { i18n } from '@translations/i18n';
import { i18n as courseI18n } from '@translations/coursei18n';

import { LocalStorage, StorageKeys } from './LocalStorage';

export const getLanguage = () => {
    const lng = i18n.language;
    return lng || 'en';
};

export const addI18Resource = (
    key: string,
    namespace: string,
    json: object,
    deep: boolean,
) => {
    i18n.addResourceBundle(key, namespace, json, deep);
};

export const addCourseI18Resource = (
    key: string,
    namespace: string,
    json: object,
    deep: boolean,
) => {
    courseI18n.addResourceBundle(key, namespace, json, deep);
};

export const getLocalisationData = async () => {
    const etag: string = await LocalStorage.get(StorageKeys.LOCALIZATION_ETAG);
    const json: any = await LocalStorage.get(StorageKeys.LOCALISATION_JSON);
    return { etag, json };
};

export const updateLocalisationData = async (localisationData: {
  etag: string;
  json: any;
}) => {
    await LocalStorage.set(StorageKeys.LOCALIZATION_ETAG, localisationData.etag);
    await LocalStorage.set(StorageKeys.LOCALISATION_JSON, localisationData.json);
};

export const getLocalizationFileUrl = (language) => {
    switch (language) {
    case AppLanguage.English:
        return AppLanguageTranslationFile.English;
    case AppLanguage.Portuguese:
        return AppLanguageTranslationFile.Portuguese;
    case AppLanguage.EnglishUS:
        return AppLanguageTranslationFile.EnglishUS;
    case AppLanguage.Spanish:
        return AppLanguageTranslationFile.Spanish;
    case AppLanguage.Hindi:
        return AppLanguageTranslationFile.Hindi;
    case AppLanguage.Bahasa:
        return AppLanguageTranslationFile.Bahasa;
    case AppLanguage.Filipino:
        return AppLanguageTranslationFile.Filipino;
    case AppLanguage.Kannada:
        return AppLanguageTranslationFile.Kannada;
    case AppLanguage.Bangla:
        return AppLanguageTranslationFile.Bangla;  
    case AppLanguage.Odia:
        return AppLanguageTranslationFile.Odia; 
    case AppLanguage.Punjabi:
        return AppLanguageTranslationFile.Punjabi; 
    case AppLanguage.Marathi:
        return AppLanguageTranslationFile.Marathi;
    case AppLanguage.Tamil:
        return AppLanguageTranslationFile.Tamil;
    case AppLanguage.Telugu:
        return AppLanguageTranslationFile.Telugu;
    case AppLanguage.Gujarati:
        return AppLanguageTranslationFile.Gujarati;  
    case AppLanguage.Malayalam:
        return AppLanguageTranslationFile.Malayalam; 
    case AppLanguage.French:
        return AppLanguageTranslationFile.French;                    
    }
};

