
import { APIv1, APIv2 } from "@common/network/constants";

import { ApiClient, wfcommonApiClient } from "@network/client";
import { IAIFeedback, PromptPayload } from "@models/AICoachModel";

const ENDPOINTS = {
  fetchPrompts: () => `${APIv1}/tagsservice/tags`,
  fetchConversation: (entityId: string) => `${APIv1}/transformerservice/entity/${entityId}/conversations`,
  sendPropmts: (entityId: string) => `${APIv1}/transformerservice/entity/${entityId}/openai`,

  deleteConversation: (entityId: string) => `${APIv1}/transformerservice/entity/${entityId}/conversations`,
  getShortcutMetadata: () => `${APIv2}/transformerservice/shortcutMetadata`,

  fetchInterviewQuestions: (jobRole: string) => `${APIv1}/transformerservice/questions?jobRole=${jobRole}`,
  fetchJobRoles: () => `${APIv1}/transformerservice/attributeValues?groupBy=learnerJob`,
  userFeedBack: (userId: string) => `${APIv1}/transformerservice/user/${userId}/feedback`
};

class AICoachRepository {
  private apiClient: any;
  private commonApiClient: any;

  constructor(apiClient: any,commonApiClient:any) {
    this.apiClient = apiClient;
    this.commonApiClient = commonApiClient;
  }

  public fetchPrompts = async (paylaod: PromptPayload) => {
    const response = await this.commonApiClient.get(ENDPOINTS.fetchPrompts(), paylaod);
    return response;
  };

  public fetchConversations = async (entityId: string, page?: number, limit?: number, tags?: any) => {
        const params = {
      pageNumnber: page,
      limit,
      ...(tags && { tags: JSON.stringify(tags) }),
    }
    const response = await this.apiClient.get(
      ENDPOINTS.fetchConversation(entityId),params
    );
    return response;
  };


  public deleteConversations = async (entityId: string,) => {
    const response = await this.apiClient.delete(
      ENDPOINTS.deleteConversation(entityId)
    );
    return response;
  };

  public getPromptMetadata = async (payload: any,) => {
    const response = await this.apiClient.get(
      ENDPOINTS.getShortcutMetadata(), payload
    );
    return response;
  };
  
  public fetchInterviewQuestions = async(jobRole: string): Promise<any> => {
    const response = await this.apiClient.get(ENDPOINTS.fetchInterviewQuestions(jobRole));
    return response;
  };

  public fetchJobRoles = async (payload: any) => {
    const { search, status } = payload;
    const queryParams = {};
    if (payload) {
      queryParams['search'] = search;
      queryParams['status'] = status;
    }  
    const response = await this.apiClient.get(ENDPOINTS.fetchJobRoles(), queryParams);
    return response?.data;
  };
  public userFeedback = async (payload: IAIFeedback, userId: string) => {
    const response = await this.apiClient.post(ENDPOINTS.userFeedBack(userId), payload);
    return response;
  }

}

const aiCoachRepository = new AICoachRepository(ApiClient,wfcommonApiClient);

export { aiCoachRepository as AICoachRepository };
