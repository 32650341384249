import { initReactI18next } from 'react-i18next';

import { Logger } from '@utils/Logger';

import i18next, { i18n } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enUS from '../presentation/CourseDetails/Components/AICoach/jsons/en-Us.json';
import en from '../presentation/CourseDetails/Components/AICoach/jsons/en.json';
import es from '../presentation/CourseDetails/Components/AICoach/jsons/es.json';
import pt from '../presentation/CourseDetails/Components/AICoach/jsons/pt.json';
import hi from '../presentation/CourseDetails/Components/AICoach/jsons/hi.json';
import ba from '../presentation/CourseDetails/Components/AICoach/jsons/ba.json';
import fil from '../presentation/CourseDetails/Components/AICoach/jsons/fil.json';
import kn from '../presentation/CourseDetails/Components/AICoach/jsons/kn.json';
import bn from '../presentation/CourseDetails/Components/AICoach/jsons/bn.json';
import or from '../presentation/CourseDetails/Components/AICoach/jsons/or.json';
import pa from '../presentation/CourseDetails/Components/AICoach/jsons/pa.json';
import mr from '../presentation/CourseDetails/Components/AICoach/jsons/mr.json';
import ta from '../presentation/CourseDetails/Components/AICoach/jsons/ta.json';
import te from '../presentation/CourseDetails/Components/AICoach/jsons/te.json';
import gu from '../presentation/CourseDetails/Components/AICoach/jsons/gu.json';
import ml from '../presentation/CourseDetails/Components/AICoach/jsons/ml.json';
import fr from '../presentation/CourseDetails/Components/AICoach/jsons/fr.json';

const resources = {
  en: {
    cou: en,
  },
  es: {
    cou: es,
  },
  pt: {
    cou: pt,
  },
  hi: {
    cou: hi
  },
  ba: {
    cou: ba
  },
  "en-US": {
    cou: enUS
  },
  "fil": {
    cou: fil
  },
  "kn": {
    cou: kn
  },
  "bn": {
    cou: bn
  },
  "or": {
    cou: or
  },
  "pa": {
    cou: pa
  },
  "mr": {
    cou: mr
  },
  "ta": {
    cou: ta
  },
  "te": {
    cou: te
  },
  "gu": {
    cou: gu
  },
  "ml": {
    cou: ml
  },
  "fr": {
    cou: fr
  }
};

const detectionOptions = {
  // order and from where course language should be detected
  order: ['path', 'subdomain', 'querystring', 'navigator', 'htmlTag'],

  lookupQuerystring: 'lng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // only detect languages that are in the whitelist
  checkWhitelist: true,
};

const i18nOptions = {
  compatibilityJSON: 'v3',
  fallbackLng: 'en',
  lng: 'en',
  whitelist: ['en', 'es', 'pt', 'en-US', 'hi', 'ba', 'fil', 'kn', 'bn', 'or', 'pa', 'mr', 'ta', 'te', 'gu', 'ml', 'fr'],
  debug: true,
  defaultNS: 'cou',
  contextSeparator: '-',
  pluralSeparator: '-',
  returnObjects: true,
  load: 'languageOnly',
  interpolation: {
    escapeValue: false,
    prefix: '{{',
    suffix: '}}'
  },
  resources,
  detection: detectionOptions,
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: true,
  },
};

class CourseI18nInstance {
  private i18n: any;

  private async init() {
    Logger.info('[CourseI18nInstance - init()] Creating i18n new instance');
    this.i18n = i18next.createInstance();
    this.i18n.use(LanguageDetector);
    this.i18n.use(initReactI18next);
    await this.i18n.init(i18nOptions, (err: any, t: any) => {
      if (err) {
        Logger.error(
          '[CourseI18nInstance - init()]',
          { error: 'unable to init i18n instance. err:' + err },
        );
      }
      Logger.info(
        '[CourseI18nInstance - init()]',
        { info: 'instance created successfully. Test:' + t('labelsAndTitles.HOME')},
      );
    });
  }

  public getI18n() {
    if (!this.i18n) {
      this.init();
    }
    return this.i18n;
  }
}

const courseI18nSingleton = new CourseI18nInstance();
const courseAdvantagei18n = courseI18nSingleton.getI18n();
export { courseAdvantagei18n as i18n };
