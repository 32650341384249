export const enum userRoles {
    student = 'STUDENT',
    faculty = 'FACULTY',
    regionalManager = 'REGIONAL_MANAGER',
    instituteAdmin = 'INSTITUTE_ADMIN'
};

export const FEATURE_NAME = {
  AICOACH: "ai-coach",
  INTERVIEW_PREP: "interview-prep",
  FORUM: "forum",
  CAREERS: "careers",
  LIBRARY: "library",
}

export const supportedFrontEndLanguageCodes = ['en', 'es', 'pt', 'en-US'];
