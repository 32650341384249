import { platform } from "@constants/config";

export const homepageCarousalArrowIcon = require('./Images/homepageCarousalArrow.svg');
// export const DeleteIcon = require("./Icons/deleteIcon.svg");
export const ChatIcon = require('./Icons/chat.svg');
export const NewChat = require('./Icons/NewChat.svg');
export const PlaceholderImage = require("./Images/PlaceholderGrey.jpg");
export const SendMessage = require('./Icons/SendMessage.svg');
export const ModalClose = require("./Icons/ModalClose.svg");
//@ts-ignore
export const SignIn = platform.WITP ? require('./Images/witpLogin.png') : require('./Images/SignIn.png');
export const ShowPassword = require('./Icons/ShowPassword.svg');
export const HidePassword = require('./Icons/HidePassword.svg');
export const BackBigger = require('./Icons/BackBigger.svg');
export const InactiveLock = require("./Icons/InactiveLock.svg");
export const ActiveLock = require("./Icons/ActiveLock.svg");
export const Password = require("./Images/Password.png");
export const Enroll = require("./Images/Enroll.png");
//@ts-ignore
export const HoldingTablet = platform.WITP ? require("./Images/holdingTabletWitp.png") : require("./Images/holdingTablet.png");
export const GroupPic = require("./Images/Vector.png");
export const WelcomePic = require("./Images/WelcomeImage.png");
export const LoadingGif = require('./Images/loading.gif');
export const EmptyResult = require('./Images/EmptySearchResult.png');
export const EmptyResultGray = require('./Images/EmptySearchGray.png');
export const OpenLibrary = require('./Images/open-lib.png');
export const Cookies = require('./Images/cookies.png');
export const InterviewPrep_EN = require('./Images/interview(EN).png');
export const InterviewPrep_ES = require('./Images/interview(ES).png');
export const InterviewPrep_PT = require('./Images/interview(PT).png');
export const ToolkitScreenShot_EN = require('./Images/toolkitSS(EN).png');
export const ToolkitScreenShot_ES = require('./Images/toolkitSS(ES).png');
export const ToolkitScreenShot_PT = require('./Images/toolkitSS(PT).png');
export const FacToolkitScreenShot_EN = require('./Images/facToolkitSS(EN).png');
export const FacToolkitScreenShot_ES = require('./Images/facToolkitSS(ES).png');
export const FacToolkitScreenShot_PT = require('./Images/facToolkitSS(PT).png');
export const DownloadOutlined = require('./Icons/DownloadOutlined.svg');
