export const customText = {
    jobRoles: "job-roles",
    fetchAllJobRoles: "fetchAllJobRoles",
    status: "VERIFIED",
    aiCoach: 'aiCoach',
    philippinesLangauge: "philippines",
    interview: 'interview',
    generalQuestions: 'GeneralQuestions',
    interviewQuestions: 'interviewQuestions',
    review: 'review',
    india: 'india',
    philippines: 'philippines',
    stop: 'STOP',
    start: 'START',
    initialState: 'initialState',
    loading: 'loading',
    loaded: 'loaded',
    deleted: 'DELETED',
    PERMISSION_DENIED: 'Permission denied',
    bangladesh: 'bangladesh',
    aiCoachId: 'ai-coach'
};

export enum ExperienceType {
    Fresher = 'FRESHER',
    Experience = 'EXPERIENCED'
};

export enum selectedType {
    input = 'input',
    suggestions = 'suggestions',
    yes = 'Yes',
    jobRoleBlocked = 'JOB_ROLE_BLOCKED',
    notValid = 'not valid',
    blocked = 'Blocked'
}

export const overAllRating = ['Overall Rating', 'Valoración Global', 'Classificação geral', 'Avaliação geral', 'Avaliação Geral', 'Classificação Geral', 'Valoración general', 'Calificación general', 'Valoración General', 'Puntuación General', 'Puntuación Total', 'Calificación General', 'Overall rating', 'Valoración global'];

export const placementLanguagesId = {
    English: 'en',
    Español: 'es',
    Português: 'pt'
};

export const groupName = {
    OPEN_TEXT : "openText"
}

export const sourceFormatType = {
    SHORT_TEXT: 'shortText',
    LONG_TEXT: 'longText',
}
