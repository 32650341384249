import { JsonProperty } from 'json-object-mapper';

import { IAttendee, IDateTime, IEventMember, IFileUpload, ITag } from '@common/redux/meeting/interface';

export class MeetingModel {
    @JsonProperty({ name: 'attachments', required: false })
    public attachments: IFileUpload[] = [];

    @JsonProperty({ name: '_id', required: true })
    private id: string = '';

    @JsonProperty({ name: 'attendees', required: false })
    public attendees: IEventMember[] = [];

    @JsonProperty({ name: 'createdAt', required: false })
    public createdAt: string = "";

    @JsonProperty({ name: 'updatedAt', required: true })
    private updatedAt: string = "";

    @JsonProperty({ name: 'deletedAt', required: false })
    public deletedAt: string = "";

    @JsonProperty({ name: 'confirmedAt', required: false })
    public confirmedAt: string = "";

    @JsonProperty({ name: 'description', required: false })
    private description: string = "";

    @JsonProperty({ name: 'end', required: false })
    public end: IDateTime = null;

    @JsonProperty({ name: 'location', required: false })
    private location: string = "";

    @JsonProperty({ name: 'organizer', required: true })
    public organizer: IEventMember = null;

    @JsonProperty({ name: 'start', required: false })
    private start: IDateTime = null;

    @JsonProperty({ name: 'status', required: true })
    private status: string = "";

    @JsonProperty({ name: 'title', required: true })
    private title: string = "";

    @JsonProperty({ name: 'tags', required: false })
    public tags: ITag = null;

    @JsonProperty({ name: 'zoomMeetingId', required: false })
    private zoomMeetingId: string = "";

    @JsonProperty({ name: 'meetingLink', required: false })
    private meetingLink: string = "";

    @JsonProperty({ name: 'isZoomMeeting', required: false })
    private isZoomMeeting: boolean = false;

    public getAttachments(): IFileUpload[] {
        return this.attachments;
    }

    public getAttendees(): IEventMember[] {
        return this.attendees;
    }

    public setAttendees(attendees: IEventMember[]): void {
        this.attendees = attendees;
    }

    public getCreatedAt(): string {
        return this.createdAt;
    }

    public getUpdatedAt(): string {
        return this.updatedAt;
    }

    public getDeletedAt(): string {
        return this.deletedAt;
    }

    public getConfirmedAt(): string {
        return this.confirmedAt;
    }

    public getDescription(): string {
        return this.description;
    }

    public getEnd(): IDateTime {
        return this.end;
    }

    public getStart(): IDateTime {
        return this.start;
    }

    public getOrganizer(): IAttendee {
        return this.organizer;
    }

    public getLocation(): string {
        return this.location;
    }

    public getStatus(): string {
        return this.status;
    }

    public getTitle(): string {
        return this.title;
    }

    public getTags(): ITag {
        return this.tags;
    }

    public getId(): string {
        return this.id;
    }

    public setOrganizerDetail(organizer: IEventMember): void {
        this.organizer = organizer;
    }

    public getOrganizerDetail(): IEventMember {
        return this.organizer;
    }
   public getZoomMeetingLink(): string {
        return this.meetingLink;
    }

    public getIsZoomMeeting(): boolean {
        return this.isZoomMeeting;
    }

    public getZoomMeetingId(): string {
        return this.zoomMeetingId;
    }
}
