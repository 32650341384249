import { connect } from 'react-redux';

import { logoutAction, setWebView, superAdminSignIn } from '@common/redux/auth/actions';
import { IUserModel } from '@common/redux/auth/interface';
import { getLogoutLoaderSelector, isAuthenticatedSelector, isWebView, maintenanceModeSelector } from '@common/redux/auth/selectors';
import { fetchLanguages } from '@redux/common/actions';
import { getLangaugesSelector, getLanguagesLoaderSelector } from '@redux/common/selectors';
import { fetchProfileUserDetailsSelector } from '@redux/profile/selectors';
import { fetchAppData } from '@redux/root/actions';
import { getAppDataLoaderSelector } from '@redux/root/selectors';
import { fetchLmsSession } from '@common/redux/course/actions';
import { fetchLmsSessionSelector,fetchLmsSessionLoaderSelector } from '@common/redux/course/selectors';

import Root from '@presentation/Root';

import * as Sentry from "@sentry/react";
import { IState } from '@store/webInterfaces';

export const mapStateToProps = (state: IState) => {
  return {
    isAuthenticated: isAuthenticatedSelector(state),
    isWebView: isWebView(state),
    userProfile: fetchProfileUserDetailsSelector(state),
    appDataLoading: getAppDataLoaderSelector(state),
    languages: getLangaugesSelector(state),
    languagesLoading: getLanguagesLoaderSelector(state),
    getLogoutLoaderSelector: getLogoutLoaderSelector(state),
    maintenanceMode: maintenanceModeSelector(state),
    LmsSession: fetchLmsSessionSelector(state),
    isFetchingLmsSession: fetchLmsSessionLoaderSelector(state),
  };
};

export const mapDispatchToProps = (dispatch: any) => {
  return {
    setWebView: () => dispatch(setWebView()),
    getAppData: () => dispatch(fetchAppData()),
    fetchLanguages: () => dispatch(fetchLanguages()),
    logout: (installationId: string) => dispatch(logoutAction(installationId)),
    superAdminSignIn: (data: IUserModel) => dispatch(superAdminSignIn(data)),
    fetchLmsSession: () => dispatch(fetchLmsSession())
  };
};

export default Sentry.withProfiler(connect(mapStateToProps, mapDispatchToProps)(Root));
