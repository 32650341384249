export enum statusType {
    active = 'ACTIVE',
    inActive = 'INACTIVE',
    both = 'BOTH'
};

export enum CourseLabels {
    ZeroToFifty = " 0 to 50",
    FiftyOneToSixtyNine = " 51 to 69",
    SeventyToHundred = " 70 to 100",
};

export enum AssessmentType {
    summativeAssessment = "Summative Assessment",
    formativeAssessmentOne = "Formative Assessment 1",
    formativeAssessmentTwo = "Formative Assessment 2",
    formativeAssessmentThree = "Formative Assessment 3",
    formativeAssessmentFour = "Formative Assessment 4",
};

export enum InstituteAdminCustomText {
    allFaculties = 'ALL_FACULTIES',
    all = 'All Faculty'
};

export enum customType {
    dashboard = 'dashboard',
    all = 'All',
    download = 'download',
    student = 'student',
    batch = 'batch',
    heading = 'heading',
    regular = 'regular'
};