import { call, put, select, takeLatest } from "redux-saga/effects";

import { getErrorCodes } from "@utils/ErrorMessageUtils";
import { getLoggedInUserId } from "@utils/UserUtils";

import { Institute } from "@common/domain/models/Institute";
import { CourseRepository } from "@common/domain/repositories/CourseRepository";

import { fetchOpenCourseList } from "@redux/course/actions";

import { InstituteRepository } from "../../domain/repositories/InstituteRepository";
import {
  fetchCertifiedCoursesFailure,
  fetchCertifiedCoursesSuccess,
  fetchInstituteFacultiesFailure,
  fetchInstituteFacultiesSuccess,
  fetchInstitutesFailure,
  fetchInstitutesSuccess,
  fetchMultipleInstitutes,
  fetchMultipleInstitutesFail,
  fetchMultipleInstitutesSuccess,
  fetchUserInstituteFailure,
  fetchUserInstituteSuccess,
  IFetchInstitutesAction,
  institutesActions,
} from "./actions";
import { getInstituteByIdSelector } from "./selectors";

export function* fetchInstitutesSaga(): any {
  try {
    const batches: any[] = yield call(InstituteRepository.fetchInstitutes);
    yield put(fetchInstitutesSuccess(batches));
  } catch (e) {
    yield put(fetchInstitutesFailure(e.message));
  }
}

export function* fetchCoursesSaga(action: IFetchInstitutesAction): any {
  try {
    const { instituteId, facultyId, courseRootIds } = action.payload;
    let courses;
    if (!courseRootIds) {
      courses = yield call(
        InstituteRepository.fetchFacultyCertifiedCourses,
        instituteId, facultyId
      );
    } else {
      courses = courseRootIds;
    }
    
    if (courses?.length > 0) {
      const certifiedCourses = yield call(CourseRepository.fetchLatestCourses, { courseRootIds: courses, status: action.payload.status,limit: 50  });
      yield put(fetchCertifiedCoursesSuccess(certifiedCourses));
    } else {
      yield put(fetchCertifiedCoursesSuccess({ courses: [], total: 0 }));
    }

  } catch (e) {
    yield put(fetchCertifiedCoursesFailure(e.message));
  }
}

export function* fetchMultipleInstituteDetailSaga(action: any): any {
  try {
    const institutes: Institute[] = yield call(
      InstituteRepository.fetchMultipleInstituteDetails,
      action.payload
    );
    yield put(fetchMultipleInstitutesSuccess(institutes));
  } catch (e) {
    yield put(fetchMultipleInstitutesFail(e.message));
  }
}

export function* fetchInstituteFacultiesSaga(action) {
  try {
    const { instituteId, courseId } = action.payload;
    const faculties = yield call(InstituteRepository.fetchInstituteFaculties, instituteId, courseId);
    yield put(fetchInstituteFacultiesSuccess(faculties));
  } catch (e) {
    const error = getErrorCodes(e);
    yield put(fetchInstituteFacultiesFailure(error));
  }
}

export function* fetchUserInstituteSaga(action) {
  try {
    let response = [];
    const institutes = yield call(InstituteRepository.fetchUserInstitutes, action.payload);
    const instituteIds = institutes.map(inst => inst?.instituteId);
    if (instituteIds?.length > 0) {
      response = yield call(InstituteRepository.fetchMultipleInstituteDetails, instituteIds);
    }
    yield put(fetchUserInstituteSuccess(response));
  } catch (e) {
    const error = getErrorCodes(e);
    yield put(fetchUserInstituteFailure(error));
  }
}

export function* watchInstitutes(): any {
  yield takeLatest(institutesActions.FETCH_INSTITUTES, fetchInstitutesSaga);
  yield takeLatest(institutesActions.FETCH_CERTIFIED_COURSES, fetchCoursesSaga);
  yield takeLatest(institutesActions.FETCH_MULTIPLE_INSTITUTES_DETAIL, fetchMultipleInstituteDetailSaga);
  yield takeLatest(institutesActions.FETCH_INSTITUTE_FACULTIES, fetchInstituteFacultiesSaga);
  yield takeLatest(institutesActions.FETCH_USER_INSTITUTE, fetchUserInstituteSaga);
}

