import { deserializeJsonObj, deserializeJsonObjCollection} from "@utils/ObjectMapperUtil";

import { wfcommonApiClient } from "@common/network/client";
import { ApiClient } from "@common/network/client";
import { APIBaseServices, clearCacheForService } from "@common/network/clientCacheHandler";
import { APIv1 } from "@common/network/constants";
import { IApiClient } from "@common/network/interfaces";
import { IFetchEvent } from "@common/redux/event/interface";

import { EventModel } from "../models/Event";


const ENDPOINTS = {
    fetchRecommendedEvents: (userId: string) => `${APIv1}/schedulingservice/users/${userId}/webinars`,
    fetchUpcomingEvents: (userId: string) => `${APIv1}/schedulingservice/users/${userId}/webinars/me`,
    event: (eventId: string, userId: string) => `${APIv1}/schedulingservice/webinar/users/${userId}/webinar/${eventId}`,
    registerEvents: (eventId: string, userId: string) => `${APIv1}/schedulingservice/webinar/${eventId}/register?userId=${userId}`
}

class EventRepository {
    private commonApiClient: IApiClient;
    constructor(commonApiClient: IApiClient) {
        this.commonApiClient = commonApiClient;
    }


    public fetchRecommendedEvents = async (payload: IFetchEvent): Promise<EventModel[]> => {
        const { userId, tags, startDate, endDate, limit, clientName } = payload;
        const response = await this.commonApiClient.get(ENDPOINTS.fetchRecommendedEvents(userId), { tags, startDate, endDate, limit, clientName  });
        return deserializeJsonObjCollection(response.webinars, EventModel, 'fetchRecommendedEvents') as EventModel[];
    }

    public fetchUpcomingEvents = async (payload: IFetchEvent): Promise<EventModel[]> => {
        const { userId, key, startDate, endDate, limit, status, clientName } = payload;
        const response = await this.commonApiClient.get(ENDPOINTS.fetchUpcomingEvents(userId), { key, startDate, endDate, limit, status, clientName }, true);
        return deserializeJsonObjCollection(response.webinars, EventModel, 'fetchUpcomingEvents') as EventModel[];
    }

    public fetchEventDetail = async (payload): Promise<EventModel> => {
        const { userId, eventId } = payload;
        const response = await this.commonApiClient.get(ENDPOINTS.event(eventId, userId));
        return deserializeJsonObj(response, EventModel, 'fetchEventDetail') as EventModel;
    }

    public registerEvents = async (payload: any): Promise<any> => {
        const { userId, eventId } = payload;
        const response = await this.commonApiClient.post(ENDPOINTS.registerEvents(eventId, userId), payload);
        clearCacheForService([APIBaseServices.SCHEDULING_SERVICE]);
        return response;
    }

}

const eventRepository = new EventRepository(wfcommonApiClient);


export { eventRepository as EventRepository };

