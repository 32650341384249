import AsyncStorage from '@callstack/async-storage';

export const StorageKeys = {
  USER: '@user',
  TOKEN: '@token',
  PASSWORD_RESET: '@passwordReset',
  IS_PRIVACY_POLICY_ACCEPTED: '@isPrivacyPolicyAccepted',
  USER_ID: '@user:id',
  USER_UUID: '@uuid',
  FCM_TOKEN: '@FCMtoken',
  PROFILE_ID: '@profileId',
  PROFILE_UUID: '@profileUuid',
  HAS_DEEP_LINKS: '@hasDeepLinks',
  USER_ECOSYSTEM_ID: '@userEcosystemId',
  USER_SETTINGS: '@userSettings',
  IS_VERIFIED: '@isVerified',
  GUEST_USER_LANG: '@guestUserLangId',
  LOCALIZATION_ETAG: '@localizationETag',
  LOCALISATION_JSON: '@jsonFile',
  EMAIL: '@email',

  USER_ROLE: '@role',
  USER_NAME: '@userName',

  // moved form localStorageConstants.js
  ACCESS_TOKEN: '@accessToken',
  REFRESH_TOKEN: '@refreshToken',
  IS_PROFILE_COMPLETED: '@isProfileCompleted',
  IS_EMAIL_VERIFIED: '@isEmailVerified',
  TEMP_PASS_GENERATED: '@tempPasswordGenerated',
  PREFERRED_LANGUAGE: '@preferredLanguage',
  JOB_ROLE: '@jobRole',
  INTREVIEW_PREP_JOB_ROLE: '@interviewPrepJobRole',

  IS_WEBVIEW: '@isWebView',
  IS_TERMS_AND_CONDITIONS_ACCEPTED: "@isTermsAndConditionsAccepted",
  IS_SUPER_ADMIN: '@isSuperAdmin',

  SOCIAL_MEDIA_USER_INFO: '@socialMediaUserInfo',
  FC_RESTORE_ID: '@restoreId',
  USER_LOCATION: '@userLocation',
  INSTITUTE_ADMIN_COURSE_NAME: '@instituteAdminCourseName',
  SHOW_APP_NUDGE: '@showAppNudge',
  I18: "i18nextLng",
  PLACEMNET_PREP_LANGUAGE: '@placementPrepLocation',
  LEARNER_SECTOR: '@learnerSector',
  LEARNER_IDEA: '@learnerIdea',

};

async function set<T>(key: string, data: T): Promise<void> {
  await AsyncStorage.setItem(key, JSON.stringify(data));
}

async function get<T>(key: string): Promise<T | null> {
  const value: string | null = await AsyncStorage.getItem(key);
  if (!value) {
    return null;
  }
  const data: T = JSON.parse(JSON.stringify(value)) as T;
  return data;
}

async function clear<T>(key: string): Promise<void> {
  await AsyncStorage.removeItem(key);
}

async function clearAll<T>(): Promise<void> {
  await AsyncStorage.clear();
}


export const LocalStorage = {
  get,
  set,
  clear,
  clearAll,
};
