import { JsonProperty } from "json-object-mapper";

import { AVG_SCORE_DATA } from "@constants/config";

export interface IEnrolledCourse {
  courseRootId: string;
  userId: string;
  courseId: string;
  batchId: number;
  status: string;
}

export interface IFetchLatestCoursePayload {
  courseRootIds?: string[];
  userId?: string;
  courseIds?: string[];
  servingMode?: string;
  status?: string;
  name?: string;
  limit?: number;
  page?: number;
}

export interface IFetchEnrolledCourses {
  userId?: string;
  pageNumber?: number;
  limit?: number;
  status?: string;
  courseId?: string;
  callEnrollCourseResponseOnly?: boolean;
}

export interface IFetchCourseActivity {
  userId: string;
  courseIds: string[];
}

export interface ILMSSession {
  session: string;
  expiry: number;
}
export class CourseModel {
  @JsonProperty({ name: "countries", required: false })
  private countries: string[] = [];

  @JsonProperty({ name: "courseLmsId", required: true })
  private courseLmsId: number = 0;

  @JsonProperty({ name: "courseRootId", required: false })
  private courseRootId: string = "";

  @JsonProperty({ name: "createdAt", required: false })
  private createdAt: string = "";

  @JsonProperty({ name: "deleted", required: false })
  private deleted: boolean = false;

  @JsonProperty({ name: "description", required: false })
  private description: string = "";

  @JsonProperty({ name: "forums", required: false })
  private forums: string[] = [];

  @JsonProperty({ name: "id", required: true })
  private id: string = "";

  @JsonProperty({ name: "image", required: false })
  private image: string = "";

  @JsonProperty({ name: "languageCode", required: false })
  private languageCode: string = "";

  @JsonProperty({ name: "lmsVendor", required: false })
  private lmsVendor: string = "";

  @JsonProperty({ name: "name", required: true })
  public name: string = "";

  @JsonProperty({ name: "servingMode", required: false })
  private servingMode: string = "";

  @JsonProperty({ name: "status", required: true })
  private status: string = "";

  @JsonProperty({ name: "tags", required: false })
  private tags: any = null;

  @JsonProperty({ name: "updatedAt", required: false })
  private updatedAt: string = "";

  @JsonProperty({ name: "version", required: false })
  private version: string = "";

  @JsonProperty({ name: "metadata", required: false })
  private metadata: {} = null;

  @JsonProperty({ name: "duration", required: false })
  private duration: string = "";

  @JsonProperty({ name: "certificationTemplate", required: false })
  private certificationTemplate: string = "";

  @JsonProperty({ name: "certificateThreshold", required: false })
  private certificateThreshold: string = "";

  @JsonProperty({ name: "userStatus", required: false })
  public userStatus: string = "";

  @JsonProperty({ name: "additionalInformation", required: false })
  public additionalInformation = [];

  @JsonProperty({ name: "supportedLanguagesForAI", required: false })
  public supportedLanguagesForAI: string[] = [];

  @JsonProperty({name:"isAssessmentBasedSkillScoreCard", required:false})
  public isAssessmentBasedSkillScoreCard: boolean = false;

  @JsonProperty({name:"courseKey", required:false})
  public courseKey: string = "";
  
  @JsonProperty({name:"isAICoachEnabled", required:false})
  public isAICoachEnabled: boolean = false;

  public getDuration(): string {
    return this.duration;
  }

  public getMetadata(): {} {
    return this.metadata;
  }

  public getCountries(): string[] {
    return this.countries;
  }

  public getCourseLmsId(): number {
    return this.courseLmsId;
  }

  public getCourseRootId(): string {
    return this.courseRootId;
  }

  public getCreatedAt(): string {
    return this.createdAt;
  }

  public getDeleted(): boolean {
    return this.deleted;
  }

  public getCourseDescription(): string {
    return this.description;
  }

  public getForums(): string[] {
    return this.forums;
  }

  public getCourseId(): string {
    return this.id;
  }

  public getThumbnail(): string {
    return `${this.image}?NO_COOKIES=1`;
  }

  public getLanguageCode(): string {
    return this.languageCode;
  }

  public getLmsVendor(): string {
    return this.lmsVendor;
  }

  public getCourseName(): string {
    return this.name;
  }

  public getServingMode(): string {
    return this.servingMode;
  }

  public getStatus(): string {
    return this.status;
  }

  public getTags() {
    return this.tags;
  }

  public getUpdatedAt(): string {
    return this.updatedAt;
  }

  public getVersion(): string {
    return this.version;
  }

  public getCertificationTemplate(): string {
    return this.certificationTemplate;
  }

  public getCertificateThreshold():string{
    return this.certificateThreshold || AVG_SCORE_DATA.toString();
  }

  public getUserStatus(): string {
    return this.userStatus;
  }

  public getAdditionalInformation() {
    return this.additionalInformation;
  }

  public getSupportedLanguagesForAI() {
    return this.supportedLanguagesForAI;
  }

  public getIsAssessmentBasedSkillScoreCard() {
    return this.isAssessmentBasedSkillScoreCard;
  }

  public getCourseKey() {
    return this.courseKey;
  }
  
  public getIsAICoachEnabled() {
    return this.isAICoachEnabled;
  }
}

