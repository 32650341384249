import React from 'react';

import { Button } from 'antd';
import { ButtonShape, ButtonType } from 'antd/lib/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import './style.less';

interface IProps {
    className?: any;
    htmlType: 'button' | 'reset' | 'submit' | undefined;
    type?: ButtonType;
    icon?: React.ReactNode;
    shape?: ButtonShape;
    size?: SizeType;
    loading?:
        | boolean
        | {
        delay?: number;
    };
    ghost?: boolean;
    danger?: boolean;
    block?: boolean;
    children?: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLElement>;
    disabled?: boolean;
    target?: string;
    href?: string;
}

const SecondaryButton = (props: IProps) => {
    const { children, className, ...rest } = props;
    const style = ['secondary_button', className].join(' ');
    return <Button className={style} type="default" {...rest}>{children}</Button>;
};

export default SecondaryButton;
