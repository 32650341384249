
import { SettingsModel } from "@common/domain/models/Settings";
import { ISettingsConfig, IUpdateSettingPayload, IUpdateSettingsPayload } from "@common/domain/models/SettingsConfig";

const actionTypePrefix = "USER_SETTINGS/";

export const settingsActions = {
  GET_USER_SETTINGS_CONFIG: `${actionTypePrefix}GET_USER_SETTINGS_CONFIG`,
  GET_USER_SETTINGS_CONFIG_SUCCESS: `${actionTypePrefix}GET_USER_SETTINGS_CONFIG_SUCCESS`,
  GET_USER_SETTINGS_CONFIG_FAILURE: `${actionTypePrefix}GET_USER_SETTINGS_CONFIG_FAILURE`,

  GET_USER_SETTINGS: `${actionTypePrefix}GET_USER_SETTINGS`,
  GET_USER_SETTINGS_SUCCESS: `${actionTypePrefix}GET_USER_SETTINGS_SUCCESS`,
  GET_USER_SETTINGS_FAILURE: `${actionTypePrefix}GET_USER_SETTINGS_FAILURE`,

  UPDATE_USER_SETTINGS: `${actionTypePrefix}UPDATE_USER_SETTINGS`,
  UPDATE_USER_SETTINGS_SUCCESS: `${actionTypePrefix}UPDATE_USER_SETTINGS_SUCCESS`,
  UPDATE_USER_SETTINGS_FAILURE: `${actionTypePrefix}UPDATE_USER_SETTINGS_FAILURE`,

  IS_FROM_MEETING_MODAL: `${actionTypePrefix}IS_FROM_MEETING_MODAL`
};


export interface IFetchRestoreIdAction {
  type: string;
  payload: {
    email: string;
    source:string;
  };
}

interface IStoreRestoreIdParams {
  email: string;
  source: string;
  restoreId: string;
}

interface IFetchRestoreIdParams {
  email: string;
  source: string;
}

export interface IStoreRestoreIdAction {
  type: string;
  payload: {
    email: string;
    source:string;
    restoreId:string;
  };
}


export const getUserSettingsConfig = () => {
  return {
    type: settingsActions.GET_USER_SETTINGS_CONFIG,
  };
};

export const getUserSettingsConfigSuccess = (settings: ISettingsConfig) => {
  return {
    type: settingsActions.GET_USER_SETTINGS_CONFIG_SUCCESS,
    payload: settings,
  };
};

export const getUserSettingsConfigFailure = (error: string) => {
  return {
    type: settingsActions.GET_USER_SETTINGS_CONFIG_FAILURE,
    payload: error,
  };
};


export const getUserSettings = (userId: string) => {
  return {
    type: settingsActions.GET_USER_SETTINGS,
    payload: {
      userId
    }
  };
};

export const getUserSettingsSuccess = (settingsData: SettingsModel) => {
  return {
    type: settingsActions.GET_USER_SETTINGS_SUCCESS,
    payload: settingsData,
  };
};

export const getUserSettingsFailure = (error: string) => {
  return {
    type: settingsActions.GET_USER_SETTINGS_FAILURE,
    payload: error,
  };
}

export const updateUserSettings = (payload:IUpdateSettingPayload): IUpdateSettingsPayload => {
  return {
    type: settingsActions.UPDATE_USER_SETTINGS,
    payload,
  };
};

export const updateUserSettingsSuccess = (settingsData: SettingsModel) => {
  return {
    type: settingsActions.UPDATE_USER_SETTINGS_SUCCESS,
    payload: settingsData,
  };
};

export const updateUserSettingsFailure = (error: string) => {
  return {
    type: settingsActions.UPDATE_USER_SETTINGS_FAILURE,
    payload: error,
  };
}

export const setIsFromMeetingModal = () => {
  return {
    type: settingsActions.IS_FROM_MEETING_MODAL
  }
}
