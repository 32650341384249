import { SettingsModel } from "@common/domain/models/Settings";
import { ISettingsConfig } from "@common/domain/models/SettingsConfig";

import { settingsActions } from "./actions";

export interface ISettingsState {
    settingsConfig: ISettingsConfig;
    userSettings: SettingsModel;
    isFromMeetingModal: boolean;
    loaders: {
        settingsConfig: boolean;
        userSettings: boolean;
        updateSettings: boolean;
    };
    errors: {
        settingsConfig: string;
        userSettings: string;
        updateSettings: string;
    };
}

export const initialState: ISettingsState = {
    settingsConfig: {} as ISettingsConfig,
    userSettings: {} as SettingsModel,
    isFromMeetingModal: false,
    loaders: {
        settingsConfig: false,
        userSettings: false,
        updateSettings: false
    },
    errors: {
        settingsConfig: "",
        userSettings: "",
        updateSettings: ""
    },
};

export const settingsReducer = (
    state: ISettingsState = initialState,
    action: { type: string; payload: any }
) => {
    switch (action.type) {
        case settingsActions.GET_USER_SETTINGS_CONFIG:
            return {
                ...state,
                settingsConfig: {},
                loaders: { ...state.loaders, settingsConfig: true },
                errors: { ...state.errors, settingsConfig: "" },
            };
        case settingsActions.GET_USER_SETTINGS_CONFIG_SUCCESS:
            return {
                ...state,
                settingsConfig: action.payload,
                loaders: { ...state.loaders, settingsConfig: false },
            };
        case settingsActions.GET_USER_SETTINGS_CONFIG_FAILURE:
            return {
                ...state,
                loaders: { ...state.loaders, settingsConfig: false },
                errors: { ...state.errors, settingsConfig: action.payload },
            };
        case settingsActions.GET_USER_SETTINGS:
            return {
                ...state,
                loaders: { ...state.loaders, userSettings: true },
                errors: { ...state.errors, userSettings: "" },
            };
        case settingsActions.GET_USER_SETTINGS_SUCCESS:
            return {
                ...state,
                userSettings: action.payload,
                loaders: { ...state.loaders, userSettings: false },
            };
        case settingsActions.GET_USER_SETTINGS_FAILURE:
            return {
                ...state,
                loaders: { ...state.loaders, userSettings: false },
                errors: { ...state.errors, userSettings: action.payload },
            };

        case settingsActions.UPDATE_USER_SETTINGS:
            return {
                ...state,
                loaders: { ...state.loaders, updateSettings: true },
                errors: { ...state.errors, updateSettings: "" },
            };
        case settingsActions.UPDATE_USER_SETTINGS_SUCCESS:
            return {
                ...state,
                userSettings: action.payload,
                loaders: { ...state.loaders, updateSettings: false },
            };
        case settingsActions.UPDATE_USER_SETTINGS_FAILURE:
            return {
                ...state,
                loaders: { ...state.loaders, updateSettings: false },
                errors: { ...state.errors, updateSettings: action.payload },
            };
        case settingsActions.IS_FROM_MEETING_MODAL:
            return {
                ...state,
                isFromMeetingModal: true
            }
        default:
            return state;
    }
};
