import React, { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Modal } from "antd";

import { getLoggedInUserId } from '@utils/UserUtils';

import { ProfileModel } from '@common/domain/models/Profile';
import { getProfileMap, isLoggedUserProfileIncompleteSelector } from '@common/redux/profile/selectors';

import { translate } from '@translations/Translater';

import TitleAndDescription from '@components/molecules/TitleAndDescription';
import Spinner from '@components/old/atoms/Spinner';

import { profileMessages } from '@containers/Profile/messages';

import { getRequiredIncompleteFields } from '@presentation/Profile/components/ProfileFields/utils';
import LegalLinks from '@presentation/auth/components/LegalLinks';

import { IState } from '@store/webInterfaces';
import './styles.less';
const ProfileFields = lazy(() => import("@presentation/Profile/components/ProfileFields"));

interface IProps {
    isProfileIncomplete?: boolean;
    profileMap?: any;
    loggedInUserId?: string;
}

const ProfileCompletionModal = (props: IProps) => {

    const {
        isProfileIncomplete,
        profileMap,
        loggedInUserId
    } = props;

    const { t } = useTranslation();

    const profile: ProfileModel = profileMap?.[profileMap?.[loggedInUserId]];

    if(!profile)
        return null;


    const {incompleteFields, visibilityMap} = getRequiredIncompleteFields(profile);

    return (
        <>
        {
            
            <Modal  open={(profile && isProfileIncomplete && incompleteFields.length > 0)}
                    className="profile-completion-modal-container"
                    footer={false}
                    closable={false}
                    >
                <div className='content'>
                <TitleAndDescription
                                description={''}
                                title={translate(t, profileMessages.profileCompleteModalTitle)}
                            />
                <Suspense fallback={<Spinner showFullPage />}>
                    <ProfileFields  profile={profile} 
                                    isPublicView={false} 
                                    visibilityMap={visibilityMap}
                                    calendlyVisible
                                    />
                </Suspense>
                </div>
                    <div className='legalLinks_container'>
                        <LegalLinks />
                    </div>
            </Modal>
        }
        </>
    );

}


export const mapStateToProps = (state: IState) => {

  
    return {  
      loggedInUserId: getLoggedInUserId(),
      profileMap: getProfileMap(state),
      isProfileIncomplete: isLoggedUserProfileIncompleteSelector(state),
    };

  };
  
export const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileCompletionModal);
