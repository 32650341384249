import { deserializeJsonObj, deserializeJsonObjCollection } from '@utils/ObjectMapperUtil';

import { APIBaseServices, clearCacheForService } from '@common/network/clientCacheHandler';
import { APIv1 } from "@common/network/constants";
import { ICreateProfilePayload } from "@common/redux/auth/interface";

import { IDepartment, IMapUserInvitationPayload, IProfile, IUserEducationQualification, ProfileModel } from '@models/Profile';

import { ApiClient } from "@network/client";

const ENDPOINTS = {
  fetchProfilesByUsersIds: () => `${APIv1}/profileservice/users`,
  updateProfile: (userId: string) => `${APIv1}/profileservice/users/${userId}`,
  fetchEducationQualifications: () => `${APIv1}/organizationservice/educationQualifications`,
  fetchDepartments: () => `${APIv1}/organizationservice/ministryGroups?include=ministries`,
  fetchProfileFromEmailId: () => `${APIv1}/profileservice/userprofile`,
  mapUserInvitations: () => `${APIv1}/organizationservice/mapUserInvitations`
};

class ProfileRepository {
  private apiClient: any;

  constructor(apiClient: any) {
    this.apiClient = apiClient;
  }

  public fetchProfilesByUserIds = async (userIds: string[]): Promise<ProfileModel[]> => {
    const params = {
      userId: userIds.toString()
    }
    const response = await this.apiClient.get(ENDPOINTS.fetchProfilesByUsersIds(), params, true);
    const profiles = deserializeJsonObjCollection(response.profile, ProfileModel, 'fetchProfilesByUserIds') as ProfileModel[];

    return profiles;
  }

  public createProfile = async (payload: ICreateProfilePayload) => {
    const response = await this.apiClient.post(ENDPOINTS.fetchProfilesByUsersIds(), payload);
    return response;
  };

  public putProfile = async (payload: { profile: IProfile, userId: string }) => {
    clearCacheForService([APIBaseServices.PROFILE_SERVICE,APIBaseServices.ORGANISATION_SERVICE,APIBaseServices.COURSE_SERVICE,APIBaseServices.TAGS_SERVICE]);
    const response = await this.apiClient.put(
      ENDPOINTS.updateProfile(payload.userId),
      payload.profile
    );
    return deserializeJsonObj(response.profile, ProfileModel, 'putProfile');
  };

  public fetchEducationQualifications = async (): Promise<IUserEducationQualification[]> => {
    const response = await this.apiClient.get(ENDPOINTS.fetchEducationQualifications())
    return response;
  }

  public fetchDepartments = async (): Promise<IDepartment[]> => {
    const response = await this.apiClient.get(ENDPOINTS.fetchDepartments())
    return response;
  }

  public fetchProfileFromEmailId = async (email: string) => {
    const params = {
      email
    };
    const response = await this.apiClient.get(ENDPOINTS.fetchProfileFromEmailId(), params);
    const profile = deserializeJsonObj(response[0], ProfileModel, 'fetchProfileFromEmailId');
    return profile;
  }

  public mapUserInvitations = async(payload:IMapUserInvitationPayload) =>{
    const response  = await this.apiClient.post(ENDPOINTS.mapUserInvitations(),payload);
    return response;
  }

}


const profileRepository = new ProfileRepository(ApiClient);

export { profileRepository as ProfileRepository };
