import { call, ForkEffect, put, select, StrictEffect, takeLatest } from "redux-saga/effects";

import { DateHelper, DATE_FORMAT_6 } from "@utils/DateHelper";
import { getErrorCodes } from "@utils/ErrorMessageUtils";
import { getLoggedInUserId } from "@utils/UserUtils";

import { MeetingModel } from "@common/domain/models/Meeting";
import { MeetingRepository } from "@common/domain/repositories/MeetingRepository";

import { fetchProfilesByUserIds } from "@redux/profile/actions";
import { fetchProfilesByUserIdsSaga } from "@redux/profile/sagas";
import { getProfileByUserIdSelector } from "@redux/profile/selectors";

import { IFluxStandardAction } from "@store/webInterfaces";
import { fetchUpcomingUserEvents } from "../event/actions";
import { fetchUpcomingEventsSaga } from "../event/sagas";
import { createEventFail,
         createEventSuccess,
         deleteEventFail,
         deleteEventSuccess,
         fetchEventDetail,
         fetchEventDetailFail,
         fetchEventDetailSuccess,
         fetchEventList,
         fetchEventListFail,
         fetchEventListSuccess,
         meetingActions, 
         updateMeetingDetailFail, 
         updateMeetingDetailSuccess} from "./actions";
import { ICreateEvent, ICreateEventAction, IFetchEvent } from "./interface";

export function* createEventSaga(action: ICreateEventAction): Generator<StrictEffect, void, string> {
    try {
        const createEventResponse: string = yield call(MeetingRepository.createEvent, action.payload);
        yield put(createEventSuccess(createEventResponse));
        const startDateTime = DateHelper.getCurrentDateInISO();
        yield call(fetchUpcomingEventsSaga, fetchUpcomingUserEvents({ userId: getLoggedInUserId(), key: 'batch', value: action.payload.tags.batch, startDateTime, limit: action.limit }));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(createEventFail(error));
    }
}

export function* fetchEventListSaga(action: IFluxStandardAction<IFetchEvent>): Generator<StrictEffect, void, MeetingModel[]> {
    try {
        const eventListResponse: MeetingModel[] = yield call(MeetingRepository.fetchEventList, action.payload);
        yield put(fetchEventListSuccess(eventListResponse));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchEventListFail(error));
    }
}

export function* fetchEventDetailSaga(action: IFluxStandardAction<string>): Generator<StrictEffect, void, MeetingModel> {
    try {
        const eventDetailResponse: MeetingModel = yield call(MeetingRepository.fetchEventDetail, action.payload);
        yield call(fetchEventAttendeesInfo, eventDetailResponse.getAttendees());
        const mappedAttendeesToEvents = yield call(mapAttendeesToEvents, eventDetailResponse);
        yield put(fetchEventDetailSuccess(mappedAttendeesToEvents));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchEventDetailFail(error));
    }
}

export function* mapAttendeesToEvents(event: MeetingModel) {
    let attendeeArray = [];
    for (let i = 0; i < event.getAttendees().length; i++) {
      const profile = yield select(getProfileByUserIdSelector, event.getAttendees()[i].userId);
      attendeeArray.push(profile);
    }
    const organizerProfile = yield select(getProfileByUserIdSelector, event.getOrganizer().userId);
    event.setAttendees(attendeeArray);
    event.setOrganizerDetail(organizerProfile);
    return event;
}

export function* fetchEventAttendeesInfo(attendees: any[]): any {
    const attendeeUserId = attendees && attendees.map((attendee: any) => {
      return attendee.userId;
    });
    if(attendeeUserId.length > 0) {
      yield call(fetchProfilesByUserIdsSaga, fetchProfilesByUserIds(attendeeUserId));
    }
}

export function* deleteEventSaga(action: IFluxStandardAction<string>): Generator<StrictEffect, void, {}> {
    try {
        yield call(MeetingRepository.deleteEvent, action.payload);
        yield put(deleteEventSuccess(action.payload));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(deleteEventFail(error));
    }
}

export function* updateMeetingSaga(action): Generator<StrictEffect, void, MeetingModel> {
    const { id, meeting } = action.payload;
    try {
        const updateMeetingResponse: MeetingModel = yield call(MeetingRepository.updateMeetingDetail, id, meeting);
        yield call(fetchEventDetailSaga, fetchEventDetail(updateMeetingResponse?.getId()));
        yield put(updateMeetingDetailSuccess(updateMeetingResponse));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(updateMeetingDetailFail(error));
    }
}

export function* watchMeeting(): Generator<ForkEffect> {
    yield takeLatest(meetingActions.CREATE_EVENT, createEventSaga);
    yield takeLatest(meetingActions.FETCH_EVENT_LIST, fetchEventListSaga);
    yield takeLatest(meetingActions.FETCH_EVENT_DETAIL, fetchEventDetailSaga);
    yield takeLatest(meetingActions.DELETE_EVENT, deleteEventSaga);
    yield takeLatest(meetingActions.UPDATE_MEETING_DETAIL, updateMeetingSaga);
}


