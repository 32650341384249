import { APIv1 } from "@common/network/constants";
import { IUnauthorizeZoomIntegrationPayload } from "@common/redux/zoom/actions";

import { ApiClient } from "@network/client";

const ENDPOINTS = {
    oAuthZoomIntegration: () => `${APIv1}/schedulingservice/oauth/zoom`,
    zoomAccessToken: () => `${APIv1}/schedulingservice/oauth/zoom/token`,
    refreshAccessToken: () => `${APIv1}/schedulingservice/oauth/zoom/refreshtoken`,
    unAuthorizeZoomIntegration: () => `${APIv1}/schedulingservice/oauth/zoom/revoke`,
    getZoomDetails:(meetingId:string)=>  `${APIv1}/schedulingservice/oauth/zoom/meeting/${meetingId}`
};

class ZoomRepository {
    private apiClient: any;

    constructor(apiClient: any) {
        this.apiClient = apiClient;
    }

    public oAuthZoomIntegration = async () => {
        const response = await this.apiClient.post(ENDPOINTS.oAuthZoomIntegration());
        return response;
    };
    public zoomAccessToken = async (userId:string,code:string,clientName:string) => {
        const data = {
            userId,
            code,
            clientName
        }
        const response = await this.apiClient.post(
            ENDPOINTS.zoomAccessToken(),
            data);
        return response;
    };
    public refreshZoomAccessToken = async (userId:string) => {
        const params={
            userId
        }
        const response = await this.apiClient.get(ENDPOINTS.refreshAccessToken(),params);
        return response;
    };
    public unAuthorizeZoomIntegration = async (payload:IUnauthorizeZoomIntegrationPayload) => {
        const response = await this.apiClient.post(ENDPOINTS.unAuthorizeZoomIntegration(),payload);
        return response;
    };

    public getZoomDetails = async (meetingId:string) => {
        const params = {
            meetingId,
        }
        const response = await this.apiClient.get(
            ENDPOINTS.getZoomDetails(meetingId));
        return response;
    };

}

const zoomRepository = new ZoomRepository(ApiClient);

export { zoomRepository as ZoomRepository };
